import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import { ListingForm } from '../components/listingform';
import Banner from '../components/banner';
import Navbar from '../components/navbar';
import isLoggedIn from '../utils/isLoggedIn';

const Listing = () => {
  //set initial states, comics, characters and query filters

  const {user, token} = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch()


  // UseEffects, check if there's a user and token ---------------------------------------------------\\

  useEffect(() => {
   isLoggedIn(navigate, dispatch, '/listing/add');
  }, []);

  // Login form submit handler ---------------------------------------------------\\

  

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Badgers SAS: List your product</title>
        <meta name='page' content='list and sell your product' />
      </Helmet>
      <Navbar displayMob={true}  />
      <Banner />
      <ListingForm user={user} token={token} />
    </>
  );
};

export default Listing;
