import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import styles from './signup.module.css';
import { useDispatch } from 'react-redux';
import { logoutSuccess } from '../redux/user';
import Navbar from '../components/navbar';
import isLoggedIn from '../utils/isLoggedIn';

const Settings = () => {
  // Initial state and variables ---------------------------------------------------\\

  const [feedback, setFeedback] = useState('');
  const [apiErrs, setApierrs] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = sessionStorage.getItem('token');

  const url = `${process.env.REACT_APP_API}/api/credentials`;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm({
    reValidateMode: 'onSubmit',
  });

  // UseEffects, check if there's a user and token ---------------------------------------------------\\
    useEffect(() => {
      isLoggedIn(navigate, dispatch, `/admin/settings`);
    }, []);

  // Check password complexity ---------------------------------------------------\\
  const isStrong = (password) => {
    const strongRegex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
    );
    return strongRegex.test(password);
  };

  // Logout handler ---------------------------------------------------\\
  const onLogOut = () => {
    dispatch(logoutSuccess());
    navigate('/login', { state: { goTo: '/' } });
  };

  // Handle signup ---------------------------------------------------\\
  const handleUpdate = async (data) => {
    const { oldpassword, password, c_password } = data;

    if (!isStrong(password)) {
      return setFeedback(
        'OOOOPS... Password does not satisfy complexity requirements. '
      );
    }

    if (password !== c_password) {
      return setFeedback(`OOOOPS... Passwords do not match.`);
    }

    try {
      const credentials = {
        oldpassword,
        password,
        c_password,
      };
      setSpinner(true);
      const response = await axios.put(`${url}`, credentials, {
        headers: { authorization: `Bearer ${token}` },
      });
      if (response.status === 201) {
        toast.success(response.data.message, {
          duration: 4000,
        });
        setSpinner(false);
        setTimeout(onLogOut, 2000);
      }
    } catch (error) {
      setApierrs([error.response.data]);
      setSpinner(false);
    }
  };

  // Map API Errors ---------------------------------------------------\\
  const mapErrs = (obj, idx) => {
    const keys = Object.keys(obj);
    return (
      <p className='help-block text-danger my-2' key={idx}>
        {`${keys[0].toUpperCase()}: `}
        {obj[keys[0]]}
      </p>
    );
  };

  // Rendered page ---------------------------------------------------\\
  return (
    <>
      <Helmet>
        <title>Badgers SAS: Settings</title>
        <meta name='page' content='user settings' />
      </Helmet>
      <Navbar displayMob={true} />
      <div className={styles.pan}>
        <Toaster position='top-center' reverseOrder={false} />
        <form
          onSubmit={handleSubmit(handleUpdate)}
          className='row g-3'
          action=''
        >
          {/* ----------------------- */}
          <div className=''>
            <Link className='page-links' to='/'>
              <FontAwesomeIcon icon='home' />{' '}
            </Link>
            <span className='link-span'>
              <FontAwesomeIcon icon='angle-right' />
            </span>
            <span className='link-span'>Settings</span>
          </div>
          {/* ----------------------- */}
          <div className='col-12 d-flex justify-content-center my-4'>
            <h5 className='h5'>
              Update Password <FontAwesomeIcon icon='wrench' />
            </h5>
          </div>
          <div className={`${styles.card} row`}>
            <div className='my-2 col-md-12'>
              <label htmlFor='title' className='form-label'>
                Old Password
              </label>
              <input
                className={`form-control`}
                type='password'
                name='oldpassword'
                id='oldpassword'
                placeholder='Please enter your old password'
                {...register('oldpassword', {
                  required: 'Please enter your old password.',
                })}
              />
              {errors?.oldpassword && (
                <p className='help-block text-danger my-2'>
                  {errors.oldpassword?.message}
                </p>
              )}
            </div>

            <div className='my-2 col-md-12'>
              <label htmlFor='title' className='form-label'>
                New Password
              </label>
              <input
                autoComplete='off'
                className={`form-control`}
                type='password'
                name='password'
                id='password'
                placeholder='New password, uppercase, lowercase, symbol and number.'
                {...register('password', {
                  required:
                    'Please choose a new password containing uppercase, lowercase symbol and numbers.',
                  minLength: 8,
                })}
              />
              {errors?.password && (
                <p className='help-block text-danger my-2'>
                  {errors.password?.message}
                </p>
              )}
            </div>

            <div className='my-2 col-md-12'>
              <label htmlFor='title' className='form-label'>
                Confirm Password
              </label>
              <input
                autoComplete='off'
                className={`form-control`}
                type='password'
                name='c_password'
                id='c_password'
                placeholder='Please re-enter your password.'
                {...register('c_password', {
                  required: 'Please re-enter your new password.',
                  minLength: 8,
                })}
              />
              {errors?.c_password && (
                <p className='help-block text-danger my-2'>
                  {errors.c_password?.message}
                </p>
              )}
            </div>

            {apiErrs?.validation && (
              <p className='help-block text-danger my-2'>
                {errors.validation?.message}
              </p>
            )}
            {apiErrs.length > 0 && apiErrs.map((obj, idx) => mapErrs(obj, idx))}
            {feedback && (
              <div className='newsletter'>
                <p className='help-block text-danger my-2'>{feedback}</p>
              </div>
            )}
            <div className='my-3'>
              <p>
                By clicking on update password, you agree to Badgers'{' '}
                <a
                  id='footer-link2'
                  href='https://www.bdgrs.com/'
                  target='blank'
                >
                  Terms & Conditions{'  '}
                </a>{' '}
                <a
                  id='footer-link2'
                  href='https://www.bdgrs.com/'
                  target='blank'
                >
                  and Privacy Policy.
                </a>
              </p>
            </div>
            <div className='row justify-content-center mt-4'>
              {spinner ? (
                <button type='' className={`btn ${styles.btnsignup}`}>
                  Sending data ..
                  <div
                    className='spinner-grow spinner-grow-sm'
                    role='status'
                  ></div>
                </button>
              ) : (
                <button type='submit' className={`btn ${styles.btnsignup}`}>
                  Update Password <FontAwesomeIcon icon='key' />
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Settings;
