import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useNavigate, Link, useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import CompanyFilter from '../components/company_filter';
import FooterProfile from '../components/footer_profile';
import styles from './listings.module.css';
import Navbar from '../components/navbar';
import isLoggedIn from '../utils/isLoggedIn';

const LinkCompany = () => {
  //set initial states, filters
  const { id } = useParams();
  const url = `${process.env.REACT_APP_API}`;
  const token = sessionStorage.getItem('token');
  const [isLoading, setLoading] = useState(true);
  const [count, setCount] = useState();
  const [companies, setCompanies] = useState([]);
  const [filters, setFilters] = useState({
    country: '',
    searchStr: '',
    displayCount: 20,
    page: 1,
  });

  const location = useLocation();
  const userName = location.state && location.state.userName;
  const navigate = useNavigate();
  const dispatch = useDispatch()


  //Get all registered companies  to display -------------\\
  useEffect(() => {
    const getCompanies = async () => {

      const { country, searchStr, displayCount, page } = filters;
      // Get listings on page load..
      const response = await axios.get(
        `${url}/admin/companies?country=${country}&searchStr=${searchStr}&displayCount=${displayCount}&page=${page}`,
        { headers: { authorization: `Bearer ${token}` } }
      );
      const [count, companies] = response.data;
      setCompanies(companies);
      setCount(count);

      setLoading(false);
    };

    //check if user is logged ins
    isLoggedIn(navigate, dispatch, `/user/link/${id}`);
    const timeoutId = setTimeout(() => {
      getCompanies();
    }, 300);

    return () => {
      clearTimeout(timeoutId);
      setLoading(false);
    };
  }, [filters]);

  //Handle linkUser function -------------\\
  const handleLink = async (evt) => {
    evt.preventDefault();
    const fields = new FormData(evt.currentTarget);
    const data = Object.fromEntries(fields.entries());

    axios
      .patch(`${process.env.REACT_APP_API}/admin/user/link/${id}`, data, {
        headers: { authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if ((response.status = 200)) {
          toast.success(response?.data?.message, {
            duration: 3000,
          });
          setTimeout(() => {
            navigate(`/user/${id}`);
          }, 4000);
        }
      })
      .catch((err) => {
        toast.error(
          `Error Code: ${err.response.status}. \n ${err.response.data.error}`,
          {
            duration: 3000,
          }
        );
      });
  };

  // Component return .. .. ;
  return isLoading ? (
    // Waiting data loading after async request...
    <>
      <Helmet>
        <title>Badgers SAS: Link company to user</title>
        <meta name='page' content='link company to user' />
      </Helmet>
      <div className='loading'>
        <img src='/img/loading.gif' alt='Loading' className='loading-img' />
      </div>
    </>
  ) : (
    //All companies .. map and render companies table
    <>
      <Helmet>
        <title>Badgers SAS: Link company to user</title>
        <meta name='page' content='link company to user' />
      </Helmet>
      <Navbar displayMob={true} />
      <div className=''>
        <Toaster position='top-center' reverseOrder={false} />
        <CompanyFilter
          count={count}
          filters={filters}
          setFilters={setFilters}
          displayMob={true}
        />
        <div className='container'>
          <div>
            <Link className='page-links' to='/'>
              <FontAwesomeIcon icon='home' />{' '}
            </Link>
            <span className='link-span'>
              <FontAwesomeIcon icon='angle-right' />
            </span>
            <Link className='page-links' to='/users'>
              <span>USERS </span>
            </Link>
            <span className='link-span'>
              <FontAwesomeIcon icon='angle-right' />
            </span>
            <Link className='page-links' to={`/user/${id}`}>
              <span className='link-span'>{userName.toUpperCase()}</span>
            </Link>
            <span className='link-span'>
              <FontAwesomeIcon icon='angle-right' />
            </span>
            <span>LINK A COMPANY</span>
          </div>

          <div className='container'>
            <form onSubmit={handleLink}>
              <div className='col-12 d-flex justify-content-center my-4'>
                <h4 className='h4'>
                  Link a Company <FontAwesomeIcon icon='building' />
                </h4>
                <button
                  className={styles.enroll}
                  type='submit'
                  aria-label='Add to Favourites'
                >
                  <FontAwesomeIcon name='add to favourites' icon='link' />
                </button>
              </div>
              <table className='table table-striped table-hover'>
                <thead>
                  <tr className={`${styles.headings}`}>
                    <th scope='col'>#</th>
                    <th scope='col'>SIRET</th>
                    <th scope='col'>Corporate Name</th>
                    <th scope='col'>Country</th>
                    <th scope='col'>City</th>
                    <th scope='col'>Post Code</th>
                    <th scope='col'>Address</th>
                    <th scope='col'>Updated at</th>
                    <th scope='col'>Select</th>
                  </tr>
                </thead>
                <tbody>
                  {companies.length > 0 ? (
                    companies.map((company, idx) => {
                      return (
                        <tr key={company.id} className={`${styles.rows}`}>
                          <th scope='row'>{idx + 1}</th>
                          <td>{company.siret}</td>
                          <td>{company.raisonSociale}</td>
                          <td>{company.pays}</td>
                          <td>{company.ville}</td>
                          <td>{company.postcode}</td>
                          <td>{company.address}</td>
                          <td>
                            {new Date(company.updatedAt).toLocaleString(
                              'en-UK',
                              {
                                dateStyle: 'short',
                                timeStyle: 'short',
                              }
                            )}
                          </td>
                          <td>
                            <input
                              required
                              type='radio'
                              id='companyId'
                              name='companyId'
                              value={company.id}
                              className='form-check-input'
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={8}>No companies to display ...</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </form>
          </div>
        </div>
        <FooterProfile />
      </div>
    </>
  );
};

export default LinkCompany;
