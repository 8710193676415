import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import countries from '../data/countries.json';

import { truncate } from '../utils/utils';

// Filter Component -------------------------------------------------

const QuoteFilter = ({ count, filters, setFilters, displayMob }) => {

  //Handle dynamic filter change
  const handleFilter = (evt, filterProperty) => {
    const newFilters = { ...filters };
    newFilters[filterProperty] = evt.target.value;
    setFilters(newFilters)
  };

  //Handle page number drop-down, Dynmaic as per number of query results
  const pageSelector = (filters) => {
    let options = [];
    const pages =
      count <= filters.displayCount ? 1 : ~~(count / filters.displayCount) + 1;
    for (let i = 1; i <= pages; i++) {
      options.push(
        <option value={`${i}`} key={i}>
          {i}
        </option>
      );
    }
    return options;
  };

  //Handle next page;
  const goToNext = () => {
    const pages =
      count <= filters.displayCount ? 1 : ~~(count / filters.displayCount) + 1;
    if (filters.page < pages) {
        setFilters(filters => {filters.page++; return filters})
        }
  }
  //Handle previous page;
  const goToPrev = () => {
    if (filters.page > 1) {
      setFilters((filters) => {
        filters.page--;
        return filters;
      });
    }
  };

  return (
    <div className='filter' id={displayMob ? '' : 'filter-hide'}>
      {/* Sorty by propery ie. product price or prduct name */}
      <div className='search-pan'>
        <select
          onChange={(evt) => handleFilter(evt, 'country')}
          name='searchField'
          id='search-field'
          value={filters.country}
        >
          <option value=''>LOCATION</option>
          {countries.map((d, idx) => (
            <option value={d.name} key={idx}>
              {truncate(d.name, 25)}
            </option>
          ))}
        </select>
        <input
          className='search-bar'
          onChange={(evt) => handleFilter(evt, 'searchStr')}
          value={filters.searchStr}
          type='text'
          name='title'
          id='title'
          placeholder='Search a quote request'
        />
      </div>
      <div className='pages'>
        <select
          onChange={(evt) => handleFilter(evt, 'processId')}
          name='searchField'
          id='search-field'
          value={filters.processId}
        >
          <option value=''>ProcessID</option>
          <option value='1'>1</option>
          <option value='2'>2</option>
          <option value='3'>3</option>
          <option value='4'>4</option>
          <option value='5'>5</option>
          <option value='6'>6</option>
        </select>
      </div>

      {/* Number of results per page */}
      <div className='pages'>
        <FontAwesomeIcon className='filter-icon' icon='th' />
        <select
          name='results-per-page'
          id='results-per-page'
          onChange={(evt) => handleFilter(evt, 'displayCount')}
        >
          <option value='20'>20</option>
          <option value='40'>40</option>
          <option value='60'>60</option>
          <option value='80'>80</option>
          <option value='100'>100</option>
        </select>
        <FontAwesomeIcon className='filter-icon' icon='forward' />
        <select
          value={filters.page.toString()}
          name='page'
          id='page'
          onChange={(evt) => handleFilter(evt, 'page')}
        >
          {pageSelector(filters)}
        </select>
        <FontAwesomeIcon
          onClick={goToPrev}
          className='filter-icon'
          icon='step-backward'
        />
        <FontAwesomeIcon
          onClick={goToNext}
          className='filter-icon'
          icon='step-forward'
        />
      </div>
    </div>
  );
};

export default QuoteFilter;
