import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Filter from '../components/filter';
import Product from '../components/product';
import FooterProfile from '../components/footer_profile';
import Banner from '../components/banner';
import Navbar from '../components/navbar';
import isLoggedIn from '../utils/isLoggedIn';

const Favourites = () => {
  //set initial states, comics, characters and query filters
  const url = `${process.env.REACT_APP_API}`;
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const token = sessionStorage.getItem('token');
  const [isLoading, setLoading] = useState(true);
  const [count, setCount] = useState();
  const [products, setProducts] = useState([]);
  const filters = useSelector((state) => state.filters);
   const favs = useSelector((state) => state.favs);

  //Get all products and equipments to display -------------\\
  useEffect(() => {
    const getProducts = async () => { 

      const { categorie, searchStr, displayCount, page } = filters;
      // Get comics on page load..
      const response = await axios.get(
        `${url}/admin/products/saved?categorie=${categorie}&searchStr=${searchStr}&displayCount=${displayCount}&page=${page}`, {
          headers: {authorization: `Bearer ${token}`}
        }
      );
      const [count, products] = response.data;
      setProducts(products);
      setCount(count);
      setLoading(false);
    };

    //check if user is logged in
    isLoggedIn(navigate, dispatch, `/favourites`);
    const timeoutId = setTimeout(() => {
      getProducts();
    }, 300);

    return () => {
      clearTimeout(timeoutId);
      setLoading(false);
    };
  }, [filters, favs]);

  // Component return .. .. COMIC or CHARACTER;;
  return isLoading ? (
    // Waiting data loading after async request...
    <>
      <Helmet>
        <title>Badgers SAS: Favourite prodcut listings</title>
        <meta
          name='page'
          content='product listings saved to favourite folder'
        />
      </Helmet>
      <div className='loading'>
        <img src='/img/loading.gif' alt='Loading' className='loading-img' />
      </div>
    </>
  ) : (
    //All products .. map and render product component
    <div className=''>
      <Helmet>
        <title>Badgers SAS: Favourite prodcut listings</title>
        <meta
          name='page'
          content='product listings saved to favourite folder'
        />
      </Helmet>
      <Navbar displayMob={true}  />
      <Filter count={count} displayMob={true} />
      <Banner />
      <div className='container'>
        <div>
          <Link className='page-links' to='/'>
            <FontAwesomeIcon icon='home' />{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <span className='link-span'>Saved Listings</span>
        </div>

        <div className='container main-pan'>
          <div className='col-12 d-flex justify-content-center mb-2'>
            <h5 className='h4'>
              Saved Listings <FontAwesomeIcon icon='bullhorn' />
            </h5>
          </div>

          {products.length > 0 &&
            products.map((product) => {
              return (
                <Product product={product} token={token} key={product.id} />
              );
            })}
        </div>
      </div>

      <FooterProfile />
    </div>
  );
};

export default Favourites;
