import './App.css';
import Cookies from 'js-cookie';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import JsonData from './data/data.json';
import { useDispatch } from 'react-redux';
import { loginSuccess, logoutSuccess, refreshToken } from './redux/user';
import { setFav } from './redux/favs';
import ParseJwt from './tokenComponent';
import { useState, useEffect } from 'react';
import Home from './pages/home';
import Store from './pages/store';
import Favourites from './pages/favourites';
import UserListings from './pages/userlistings';
import Login from './pages/login';
import Footer from './components/footer';
import Product from './pages/product';
import Listing from './pages/add_listing';
import FeaturedListings from './pages/featured_listings';
import ProfileUpdate from './pages/profileUpdate';
import Settings from './pages/settings';
import Listings from './pages/listings';
import QuoteRequests from './pages/quote_requests';
import QuoteRequest from './pages/quote_request_detailed';
import EditListing from './pages/edit_listing';
import Users from './pages/users';
import User from './pages/user';
import Analytics from './pages/analytics';
import Companies from './pages/companies';
import Company from './pages/company';
import UpdateCompany from './pages/update_company';
import AddCompany from './pages/add_company';
import Values from './pages/our_values';
import UserProducts from './pages/user_products';
import CompanyUsers from './pages/company_users';
import LinkCompany from './pages/link_company';
import ManageShipping from './pages/manage_shipping';
import Orders from './pages/orders';
import Carts from './pages/carts';
import Cart from './pages/cart';
import Order from './pages/order';
import axios from 'axios';

import {
  faSignOutAlt,
  faSignInAlt,
  faSmileWink,
  faUserPlus,
  faHeart,
  faPlusCircle,
  faTh,
  faForward,
  faBars,
  faStepBackward,
  faStepForward,
  faUnlock,
  faPlus,
  faBullhorn,
  faUpload,
  faHome,
  faChevronRight,
  faBarcode,
  faUser,
  faStar,
  faKey,
  faWrench,
  faPenSquare,
  faTrash,
  faCalculator,
  faLink,
  faCheckCircle,
  faQuestionCircle,
  faBuilding,
  faCheck,
  faThumbsUp,
  faChartLine,
  faAngleRight,
  faTruckMoving, faShoppingCart, faShoppingBasket
} from '@fortawesome/free-solid-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';
import Featured from './redux/featured';
library.add(
  faSignOutAlt,
  faSignInAlt,
  faSmileWink,
  faUserPlus,
  faHeart,
  faPlusCircle,
  faTh,
  faForward,
  faBars,
  faStepBackward,
  faStepForward,
  faUnlock,
  faPlus,
  faBullhorn,
  faUpload,
  faHome,
  faChevronRight,
  faBarcode,
  faUser,
  faStar,
  faKey,
  faWrench,
  faPenSquare,
  faTrash,
  faCalculator,
  faLink,
  faCheckCircle,
  faQuestionCircle,
  faBuilding,
  faCheck,
  faThumbsUp,
  faChartLine,
  faAngleRight,
  faTruckMoving, faShoppingCart, faShoppingBasket
);

function App() {
  const url = `${process.env.REACT_APP_API}/api`;
  const [landingPageData, setLandingPageData] = useState({});
  const user = JSON.parse(sessionStorage.getItem('user'));
  const token = sessionStorage.getItem('token');
  const dispatch = useDispatch();

  //  Refresh JWT at intervals---------------------------------------------------\\

  const refreshJWT = async () => {
    const token = sessionStorage.getItem('token');
    if (token) {
      const decodedJwt = ParseJwt(token);
      if (decodedJwt?.exp * 1000 > Date.now()) {
        axios
          .get(`${process.env.REACT_APP_API}/auth/refresh`, {
            headers: { authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const refreshedToken = response.data.token;
            dispatch(refreshToken({ token: refreshedToken }));
          })
          .catch((err) => {
            console.log(err);
            dispatch(logoutSuccess());
          });
      }
    }
  };

  //  UseEffects---------------------------------------------------\\

  useEffect(() => {
    const startUp = async () => {
      setLandingPageData(JsonData);
      setInterval(refreshJWT, 14 * 60 * 1000);
      if (token && user) {
        const decodedJwt = ParseJwt(token);
        if (decodedJwt?.exp * 1000 > Date.now()) {
          dispatch(loginSuccess({ user, token }));

          const favs = await axios.get(`${url}/favs`, {
            headers: { authorization: `Bearer ${token}` },
          });

          if (favs.status === 200) {
            dispatch(setFav(favs.data));
          }
        }
      }
    };
    startUp();
  }, []);


  return (
    <HelmetProvider>
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/listings' element={<Listings />} />
          <Route path='/featured' element={<FeaturedListings />} />
          <Route path='/users' element={<Users />} />
          <Route path='/store' element={<Store />} />
          <Route path='/store/orders' element={<Orders />} />
          <Route path='/store/order/:id' element={<Order />} />
          <Route path='/store/carts' element={<Carts />} />
          <Route path='/store/cart/:id' element={<Cart />} />
          <Route path='/users/company/:id' element={<CompanyUsers />} />
          <Route path='/user/:id' element={<User />} />
          <Route path='/user/link/:id' element={<LinkCompany />} />
          <Route path='/companies' element={<Companies />} />
          <Route path='/companies/enroll' element={<AddCompany />} />
          <Route path='/company/:id' element={<Company />} />
          <Route path='/company/update/:id' element={<UpdateCompany />} />
          <Route path='/quoterequests' element={<QuoteRequests />} />
          <Route path='/quoterequest/:id' element={<QuoteRequest />} />
          <Route path='/analytics' element={<Analytics />} />
          <Route
            path='/favourites'
            element={<Favourites displayMob={true} />}
          />
          <Route path='/product/:id' element={<Product />} />
          <Route path='/login' element={<Login />} />
          <Route path='/admin/update' element={<ProfileUpdate />} />
          <Route path='/admin/settings' element={<Settings />} />
          <Route path='/listing/add' element={<Listing />} />
          <Route path='/listing/edit/:id' element={<EditListing />} />
          <Route path='/listings/user/:id' element={<UserProducts />} />
          <Route path='/listing/shipping/:id' element={<ManageShipping />} />
          <Route path='/mylistings' element={<UserListings />} />
          <Route path='/values' element={<Values />} />
        </Routes>
        <Footer></Footer>
      </Router>
    </HelmetProvider>
  );
}

export default App;
