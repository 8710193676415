import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styles from './../../pages/listings.module.css';
import useAnalyticsReport from '../../redux/hooks/useAnalyticsReport';
import ChartCountryVisits from './chart_country_visits';
import analyticsstyles from './analytics.module.css';

const UsersByCountry2 = ({history}) => {
  //Compose report, data to be fetched.
  const report = useMemo(
    () => ({
      dimensions: [{ name: 'country' }],
      metrics: [{ name: 'totalUsers' }],
      dateRanges: [{ startDate: `${history}daysAgo`, endDate: 'today' }],
      offset: 0,
      limit: 20,
      orderBys: [
        {
          metric: {
            metricName: 'totalUsers',
          },
          desc: true,
        },
      ],
      metricAggregations: ['TOTAL'],
    }),
    [history]
  );

  //initial state and hooks invocation
  const { token } = useSelector((state) => state.user);
  const [usersByCountry, loadingUsers] = useAnalyticsReport(token, report);

  //map data and relative keys
  const dataMapper = (input) =>
    input.map((row) => {
      return {
        name: row.dimensionValues[0].value,
        visits: parseInt(row.metricValues[0].value),
      };
    });

  return loadingUsers ? (
    <div className='loading'>
      <img src='/img/loading.gif' alt='Loading' className='loading-img' />
    </div>
  ) : (
    <div className={analyticsstyles.countryvisits}>
      <div>
        <table className='table table-striped table-hover'>
          <thead>
            <tr className={`${styles.headings}`}>
              <th scope='col'>#</th>
              <th scope='col'>Country</th>
              <th scope='col'>Users</th>
            </tr>
          </thead>
          <tbody>
            {usersByCountry?.rows.length > 0 ? (
              usersByCountry?.rows.map((row, idx) => {
                return (
                  <tr key={idx} className={`${styles.rows}`}>
                    <th scope='row'>{idx + 1}</th>
                    <td>{row.dimensionValues[0].value}</td>
                    <td>{row.metricValues[0].value}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={11}>No data to display ...</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* -------------------Graph------------------*/}
      <div className={analyticsstyles.rcchart1}>
        <ChartCountryVisits
          data={dataMapper(usersByCountry?.rows)}
          caption={'Total Users by Country'}
        />
      </div>
    </div>
  );
};

export default UsersByCountry2;
