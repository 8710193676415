import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FooterProfile from '../components/footer_profile';
import Banner from '../components/banner';
import PanelCard from '../components/panelcard';
import { Helmet } from 'react-helmet-async';
import Navbar from '../components/navbar';
import isLoggedIn from '../utils/isLoggedIn';

const Home = () => {
  //set initial states, comics, characters and query filters
  const navigate = useNavigate();
  const dispatch = useDispatch()

  // Panel Icons to be produced ---------------------------------------------------\\

  const icons = [
    {
      link: '/listings',
      img: '/img/icons/listing.jpg',
      title: 'LISTINGS',
      icon: 'bullhorn',
      tag: '',
      desc: 'view, edit and validate uploaded listings.',
    },
    {
      link: '/featured',
      img: '/img/icons/featured.jpg',
      title: 'FEATURED LISTINGS',
      icon: 'building',
      tag: '',
      desc: 'view, add and remove listings in the featured products list.',
    },
    {
      link: '/quoterequests',
      img: '/img/icons/quote.jpg',
      title: 'QUOTES',
      icon: 'calculator',
      tag: '',
      desc: 'view, follow-up and update quotes status.',
    },
    {
      link: '/users',
      img: '/img/icons/user.jpg',
      title: 'USERS',
      icon: 'user',
      tag: '',
      desc: 'view, edit, delete, validate, assingn roles and policies.',
    },
    {
      link: '/store',
      img: '/img/icons/store.jpg',
      title: 'STORE',
      icon: 'shopping-cart',
      tag: '',
      desc: 'online store, manage orders, logistics and fulfillment.',
    },
    {
      link: '/companies',
      img: '/img/icons/company.jpg',
      title: 'COMPANIES',
      icon: 'building',
      tag: '',
      desc: 'view, create, validate, delete and assign.',
    },
    {
      link: '/analytics',
      img: '/img/icons/stats.jpg',
      title: 'ANALYTICS',
      icon: 'chart-line',
      tag: '',
      desc: 'view statics, data analytics.',
    },
  ];

  // UseEffects, check if there's a user and token ---------------------------------------------------\\

  useEffect(() => {
    isLoggedIn(navigate, dispatch, '/');
  }, []);

  // Component return .. ..
  return (
    //Main admin panel .. map and render panel icons
    <>
      <Helmet>
        <title>Badgers SAS: Admin Console</title>
        <meta name='page' content='Admin Console' />
      </Helmet>
      <Navbar displayMob={true} />
      <div className=''>
        <Banner />
        <div className='container main-pan'>
          {icons.length > 0 &&
            icons.map((icon, idx) => {
              return (
                <PanelCard
                  link={icon.link}
                  img={icon.img}
                  title={icon.title}
                  icon={icon.icon}
                  tag={icon.tag}
                  desc={icon.desc}
                  key={idx}
                />
              );
            })}
        </div>
        <FooterProfile />
      </div>
    </>
  );
};

export default Home;
