import { useParams, Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addFav, removeFav } from '../redux/favs';
import { addFeatured, removeFeatured } from '../redux/featured';
import convertEntities from '../utils/convertEntities';
import { Helmet } from 'react-helmet-async';
import styles from './product.module.css';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import Navbar from '../components/navbar';
import isLoggedIn from '../utils/isLoggedIn';

const Product = () => {
  const { id } = useParams();
  const url = `${process.env.REACT_APP_API}/admin/product/${id}`;
  const validationUrl = `${process.env.REACT_APP_API}/admin/product/validation/${id}`;
  const navigate = useNavigate();

  const [product, setProduct] = useState(); //Product details state
  const [isloading, setLoading] = useState(true); //Loading isloading toggle
  const [confirmDelete, toggleConfirmDelete] = useState(false); //Loading isloading toggle
  const [validated, setValidation] = useState();
  const { favs, featured } = useSelector((state) => state);
  const dispatch = useDispatch();
  const token = sessionStorage.getItem('token');

  // Query to get product data
  useEffect(() => {
    // check if user is logged in
    isLoggedIn(navigate, dispatch, `/product/${id}`);

    let active = true;
    (async () => {
      const productData = await axios(url, {
        headers: { authorization: `Bearer ${token}` },
      });
      if (active) {
        setProduct(productData.data);
        setValidation(productData.data.validated);
        setLoading(false);
      }
    })();
    return () => {
      active = false;
      setProduct(null);
    };
  }, [id]);

  //handle listing valitaion
  const handleValidation = async (evt) => {
    setValidation((validated) => !validated);
    try {
      const response = await axios.put(
        validationUrl,
        { validated: !validated },
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 201) {
        toast.success(response.data.message, {
          duration: 4000,
        });
      } else {
        setValidation((validated) => !validated);
        toast.error(
          `Error Code: ${response.status}. \n OOOOPS.. ${response.message}`
        );
      }
    } catch (error) {
      setValidation((validated) => !validated);
      console.log(error.response.data.errors);
    }
  };

  //Delete a listing permanently
  const deleteListing = async () => {
    const ImagesKeys = product.images?.map((photo) => photo.lienImage);
    axios
      .put(
        `${process.env.REACT_APP_API}/admin/product/delete/${id}`,
        { ImagesKeys },
        {
          headers: { authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        if ((response.status = 200)) {
          toast.success(response?.data?.message, {
            duration: 3000,
          });
          setTimeout(() => {
            navigate('/listings');
          }, 2000);
        }
      })
      .catch((err) => {
        toast.error(err.message, {
          duration: 3000,
        });
      });
  };

  //Delete confirmation button toggle handler
  const toggleDelete = () => {
    toggleConfirmDelete((state) => !state);
  };

  //Add to Favourite.. helper function
  const addFavHandler = async (evt) => {
    evt.stopPropagation();
    if (!token) {
      console.log(
        'No logged user found..Please log in before adding favourites'
      );
    } else {
      dispatch(addFav(product.id));
      const reqBody = { productId: product.id };
      axios
        .post(`${process.env.REACT_APP_API}/api/user/addfav`, reqBody, {
          headers: { authorization: `Bearer ${token}` },
        })
        .then((response) => {
          if (response.status === 200) {
          } else {
            dispatch(removeFav(product.id));
          }
        })
        .catch((err) => {
          console.log(err.message);
          dispatch(removeFav(product.id));
        });
    }
  };

  //Remov from Favourite.. helper function
  const removeFavHandler = async (evt) => {
    evt.stopPropagation();
    if (!token) {
      console.log(
        'No logged user found..Please log in before adding favourites'
      );
    } else {
      dispatch(removeFav(product.id));
      axios
        .delete(`${process.env.REACT_APP_API}/api/user/delfav/${product.id}`, {
          headers: { authorization: `Bearer ${token}` },
        })
        .then((response) => {
          if (response.status === 201) {
          } else {
            dispatch(addFav(product.id));
          }
        })
        .catch((err) => {
          console.log(err.message);
          dispatch(addFav(product.id));
        });
    }
  };

  //Add to featured.. helper function
  const addToFeatured = async (evt) => {
    evt.stopPropagation();
    if (!token) {
      console.log(
        'No logged user found..Please log in before adding to favourites'
      );
    } else {
      dispatch(addFeatured(product.id));
      const reqBody = { productId: product.id };
      axios
        .post(
          `${process.env.REACT_APP_API}/admin/product/addFeatured`,
          reqBody,
          {
            headers: { authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          if (response.status === 200) {
          } else {
            dispatch(removeFeatured(product.id));
          }
        })
        .catch((err) => {
          console.log(err.message);
          dispatch(removeFeatured(product.id));
        });
    }
  };

  //Remov from featured.. helper function
  const removeFromFeatured = async (evt) => {
    evt.stopPropagation();
    if (!token) {
      console.log(
        'No logged user found..Please log in before adding favourites'
      );
    } else {
      dispatch(removeFeatured(product.id));
      axios
        .delete(
          `${process.env.REACT_APP_API}/admin/product/delfeatured/${product.id}`,
          {
            headers: { authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          if (response.status === 204) {
          } else {
            dispatch(addFeatured(product.id));
          }
        })
        .catch((err) => {
          console.log(err.message);
          dispatch(addFeatured(product.id));
        });
    }
  };

  // -----------------------------------------------------------------------------\\

  return isloading ? (
    // Waiting data loading after async request...
    <>
      <Helmet>
        <title>Badgers SAS: Product details</title>
        <meta name='page' content='product details' />
      </Helmet>
      <div className='loading'>
        <img src='/img/loading.gif' alt='Loading' className='loading-img' />
      </div>
    </>
  ) : (
    <>
      <Helmet>
        <title>{`Badgers SAS: Product > ${product.title}`}</title>
        <meta name='page' content={`Product Details: ${product.title}`} />
      </Helmet>
      <Navbar displayMob={true} />
      <div className={`${styles.background} container`}>
        <Toaster position='top-center' reverseOrder={false} />
        {/* ------------Navlinks----------- */}
        <div className=''>
          <Link className='page-links' to='/'>
            <FontAwesomeIcon icon='home' />{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <Link className='page-links' to='/listings'>
            LISTINGS{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <span className='link-span'>{product.title}</span>
        </div>
        {/* ----------------------- */}

        {/* .....................Product Details.............. */}

        <div className={`${styles.card} row g-4 my-3 pb-3`}>
          {/* -------------Product images carrousel start---------- */}
          <div
            id='carouselExampleIndicators'
            className='col-md-6 carousel slide'
            data-bs-ride='true'
          >
            <div className='carousel-indicators'>
              {product.images.length > 0 &&
                product.images.map((photo, i) => {
                  return (
                    <button
                      key={i}
                      type='button'
                      data-bs-target='#carouselExampleIndicators'
                      data-bs-slide-to={i.toString()}
                      className={i === 0 && 'active'}
                      aria-current={i === 0 && 'true'}
                      aria-label={`Slide ${i + 1}`}
                    ></button>
                  );
                })}
            </div>
            <div className='carousel-inner'>
              {product.images.length > 0 &&
                product.images.map((photo, i) => {
                  return (
                    <div
                      className={`carousel-item ${i === 0 && 'active'}`}
                      key={i}
                    >
                      <img
                        src={photo.lienImage}
                        className='d-block w-100'
                        alt={`product-image-${i}`}
                      />
                    </div>
                  );
                })}
            </div>
            <button
              className='carousel-control-prev'
              type='button'
              data-bs-target='#carouselExampleIndicators'
              data-bs-slide='prev'
            >
              <span
                className='carousel-control-prev-icon'
                aria-hidden='true'
              ></span>
              <span className='visually-hidden'>Previous</span>
            </button>
            <button
              className='carousel-control-next'
              type='button'
              data-bs-target='#carouselExampleIndicators'
              data-bs-slide='next'
            >
              <span
                className='carousel-control-next-icon'
                aria-hidden='true'
              ></span>
              <span className='visually-hidden'>Next</span>
            </button>
          </div>

          {/* ------------Product images carrousel end----------- */}
          <div className='product-block col-md-6'>
            <div className={styles.title}>
              <h4 className='h4'>{product.title}</h4>
              {/* ------------------------------------ */}
              <div>
                {favs.includes(product.id) ? (
                  <button
                    className={`${styles.favicon} ${styles.favgold}`}
                    onClick={(evt) => removeFavHandler(evt)}
                    aria-label='Remove from Favourites'
                  >
                    <FontAwesomeIcon
                      name='remove from favourites'
                      icon='star'
                    />
                  </button>
                ) : (
                  <button
                    className={`${styles.favicon} ${styles.favgrey}`}
                    onClick={(evt) => addFavHandler(evt)}
                    aria-label='Add to Favourites'
                  >
                    <FontAwesomeIcon name='add to favourites' icon='plus' />
                  </button>
                )}

                {featured.includes(product.id) ? (
                  <button
                    className={`${styles.favicon} ${styles.favgreen}`}
                    onClick={(evt) => removeFromFeatured(evt)}
                    aria-label='Remove from featured'
                  >
                    <FontAwesomeIcon name='remove from featured' icon='check' />
                  </button>
                ) : (
                  <button
                    className={`${styles.favicon} ${styles.favgrey}`}
                    onClick={(evt) => addToFeatured(evt)}
                    aria-label='Add to featured'
                  >
                    <FontAwesomeIcon name='add to featured' icon='check' />
                  </button>
                )}
              </div>
            </div>

            {/* ------------------------------------ */}
            <h5 className='h5'>
              {product.currency === 'usd' ? '$ ' : '€ '}
              {Number(product.price).toLocaleString('en')}
            </h5>
            <p className='product-subheads product-type mb-2 mt-3'>
              <span className={styles.label}>Category:</span>
              <span className={styles.value}>{product.categorie}</span>
            </p>
            <p className='product-subheads product-type mb-2'>
              <span className={styles.label}>Type:</span>
              <span className={styles.value}>{product.type}</span>
            </p>
            <p className='product-subheads product-brand mb-2'>
              <span className={styles.label}>Brand, Manufacturer:</span>
              <span className={styles.value}>{product.brand}</span>
            </p>
            <p className='product-subheads product-condition mb-2'>
              <span className={styles.label}>Condition:</span>
              <span className={styles.value}>{product.condition}</span>
            </p>
            <p className='product-subheads product-condition mb-2'>
              <span className={styles.label}>Legal Owner:</span>
              <span className={styles.value}>{product.legalOwner}</span>
            </p>
            <p className='product-subheads product-location mb-2'>
              <span className={styles.label}>City:</span>
              <span className={styles.value}>{product.city}</span>
            </p>
            <p className='product-subheads product-location mb-2'>
              <span className={styles.label}>Country:</span>
              <span className={styles.value}>{product.country}</span>
            </p>
            <p className='product-subheads product-location mb-2'>
              <span className={styles.label}>Region:</span>
              <span className={styles.value}>{product.region}</span>
            </p>
            <p className='product-subheads product-location mb-4'>
              <span className={styles.label}>Available Units:</span>
              <span className={styles.value}>{product.qty}</span>
            </p>
            <p className='product-subheads product-location mb-2'>
              <span className={styles.label}>Updated at:</span>
              <span className={styles.value}>
                {new Date(product.updatedAt).toLocaleString('en-UK', {
                  dateStyle: 'long',
                  timeStyle: 'short',
                })}
              </span>
            </p>
            <p className='mt-3'>
              {confirmDelete ? (
                <button
                  className={`${styles.del} ${styles.btn}`}
                  onClick={deleteListing}
                >
                  {`DELETE ANYWAY??  `}
                  <FontAwesomeIcon icon='trash' />
                </button>
              ) : (
                <button
                  className={`${styles.del} ${styles.btn}`}
                  onClick={toggleDelete}
                >
                  {`DELETE LISTING  `}
                  <FontAwesomeIcon icon='trash' />
                </button>
              )}
            </p>
            <p className='mt-1'>
              <button
                className={`${styles.edit} ${styles.btn}`}
                onClick={() => navigate(`/listing/edit/${product.id}`)}
              >
                {`EDIT LISTING  `}
                <FontAwesomeIcon icon='pen-square' />
              </button>
            </p>
            <p className='mt-1'>
              <button
                className={`${styles.ship} ${styles.btn}`}
                onClick={() => navigate(`/listing/shipping/${product.id}`)}
              >
                {`SHIPPING  `}
                <FontAwesomeIcon icon='truck-moving' />
              </button>
            </p>
            <div>
              <FormControlLabel
                className='mt-2'
                control={
                  <Switch
                    checked={validated}
                    onChange={(evt) => handleValidation(evt)}
                  />
                }
                label='VALIDATION'
              />
            </div>
          </div>
        </div>
        <div className={`${styles.card} row my-3 py-3`}>
          <h5 className='h5 mb-2'>Description:</h5>
          <p className={styles.paragraph}>
            {convertEntities(product.description)}
          </p>
        </div>
      </div>
    </>
  );
};

export default Product;
