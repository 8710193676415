import React from 'react'
import styles from './panelcard.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PanelCard = ({ link, img, title, icon, tag, desc }) => {
  return (
    <div>
      <a href={link} class={styles.card}>
        <img src={img} class={styles.card__image} alt='' />
        <div class={styles.card__overlay}>
          <div class={styles.card__header}>
            <svg class={styles.card__arc} xmlns='http://www.w3.org/2000/svg'>
              <path />
            </svg>
            {/* <img class={styles.card__thumb} src={img} alt='' /> */}
            <span className={styles.card__thumb}>
              <FontAwesomeIcon icon={icon} />
            </span>
            <div class={styles.card__header_text}>
              <h3 class={styles.card__title}>{title}</h3>
              <span class={styles.card__status}>{tag}</span>
            </div>
          </div>
          <p class={styles.card__description}>{desc}</p>
        </div>
      </a>
    </div>
  );
};

export default PanelCard