import { useParams, Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import styles from './../pages/product.module.css';
import axios from 'axios';
import UserCard from '../components/user_card';
import CompanyCard from '../components/company_card';
import Navbar from '../components/navbar';
import isLoggedIn from '../utils/isLoggedIn';

const User = () => {
  const { id } = useParams();
  const url = `${process.env.REACT_APP_API}/admin/user/${id}`;

  const navigate = useNavigate();
  const dispatch = useDispatch()

  const [company, setCompany] = useState(); //Product details state
  const [user, setUser] = useState(); //Client details state
  const [isloading, setLoading] = useState(true); //Loading isloading toggle
  const token = sessionStorage.getItem('token');

  // Query to get product data
  useEffect(() => {
    // check if user is logged in
    isLoggedIn(navigate, dispatch, `/user/${id}`);

    let active = true;

    (async () => {
      const response = await axios(url, {
        headers: { authorization: `Bearer ${token}` },
      });
      if (active) {
        setUser(response.data);
        setCompany(response.data?.Entreprise);
        setLoading(false);
      }
    })();
    return () => {
      active = false;
      setUser(null);
      setCompany(null);
    };
  }, [id]);

  // -----------------------------------------------------------------------------

  return isloading ? (
    // Waiting data loading after async request...
    <>
      <Helmet>
        <title>Badgers SAS: User profile</title>
        <meta name='page' content='detailed user profile' />
      </Helmet>
      <div className='loading'>
        <img src='/img/loading.gif' alt='Loading' className='loading-img' />
      </div>
    </>
  ) : (
    <>
        <Helmet>
          <title>Badgers SAS: User profile</title>
          <meta name='page' content='detailed user profile' />
        </Helmet>
      <Navbar displayMob={true} />
      <div className={`${styles.background} container`}>
        {/* ------------Navlinks----------- */}
        <div className=''>
          <Link className='page-links' to='/'>
            <FontAwesomeIcon icon='home' />{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <Link className='page-links' to='/users'>
            USERS{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <span className='link-span'>{`${user?.prenom?.toUpperCase()} ${user?.nom?.toUpperCase()}`}</span>
        </div>
        {/* ----------------------- */}
        <div className='col-12 d-flex justify-content-center my-4'>
          <h4 className='h4'>
            User Profile <FontAwesomeIcon icon='user' />
          </h4>
        </div>

        <div className={`${styles.card} row g-4 my-3 pb-3`}>
          <UserCard user={user} />
          <CompanyCard company={company} />
        </div>
      </div>
    </>
  );
};

export default User;
