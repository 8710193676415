import React from 'react';
import {
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  CartesianGrid,
  ResponsiveContainer,
  ComposedChart,
  LabelList,
} from 'recharts';

const ChartProductEngagement = ({ data, caption }) => {
  const colors = [
    '#FF33FF',
    '#00B3E6',
    '#E6B333',
    '#3366E6',
    '#999966',
    '#99FF99',
    '#B34D4D',
    '#80B300',
    '#809900',
    '#E6B3B3',
    '#6680B3',
    '#66991A',
    '#FF99E6',
    '#CCFF1A',
    '#FF1A66',
    '#E6331A',
    '#33FFCC',
    '#66994D',
    '#B366CC',
    '#4D8000',
    '#B33300',
    '#CC80CC',
    '#66664D',
    '#991AFF',
    '#E666FF',
    '#4DB3FF',
    '#1AB399',
    '#E666B3',
    '#33991A',
    '#CC9999',
    '#B3B31A',
    '#00E680',
    '#4D8066',
    '#809980',
    '#E6FF80',
    '#1AFF33',
    '#999933',
    '#FF3380',
    '#CCCC00',
    '#66E64D',
    '#4D80CC',
    '#9900B3',
    '#E64D66',
    '#4DB380',
    '#FF4D4D',
    '#99E6E6',
    '#6666FF',
  ];

  return (
    <ResponsiveContainer width='100%' height='96%'>
      <ComposedChart
        width={500}
        height={400}
        data={data}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <XAxis dataKey='product' />
        <YAxis />
        <CartesianGrid stroke='#f5f5f5' />
        <Tooltip />
        <Legend
          dataKey='product'
          payload={[{ value: caption }]}
          verticalAlign='top'
          iconType='diamond'
        />

        <Bar dataKey='engagements' barSize={40} fill='#413ea0'>
          {data.map((p, idx) => {
            return <Cell key={p['engagements']} fill={colors[idx]} />;
          })}
        </Bar>
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default ChartProductEngagement;
