import React from 'react';
import styles from './../pages/product.module.css';

const Articles = ({ articles, currency }) => {
  return (
    <div className={`${styles.card} row g-4 my-3 pb-3`}>
      <table class='table table-responsive table-secondary table-striped'>
        <thead>
          <tr>
            <th scope='col'>N°</th>
            <th scope='col'>Article</th>
            <th scope='col' className='text-end'>
              Qty
            </th>
            <th scope='col' className='text-end'>
              Price ({currency === 'usd' ? 'US$' : '€'})
            </th>
            <th scope='col' className='text-end'>
              Total ({currency === 'usd' ? 'US$' : '€'})
            </th>
          </tr>
        </thead>
        <tbody>
          {articles.length ? (
            articles.map((article, index) => (
              <tr key={article.materielId}>
                <td>{index + 1}</td>
                <td>{`${article?.Materiel?.brand}, ${article?.Materiel.title}`}</td>
                <td className='text-end'>{article.qty}</td>
                <td className='text-end'>{article.price.toFixed(2)}</td>
                <td className='text-end'>
                  {(article.price * article.qty).toFixed(2)}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan='5'>No articles to display .......</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Articles;
