import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useNavigate, Link, useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isLoggedIn from '../utils/isLoggedIn';
import axios from 'axios';
import UserFilter from '../components/user_filter';
import FooterProfile from '../components/footer_profile';
import styles from './listings.module.css';
import Navbar from '../components/navbar';

const CompanyUsers = () => {
  //set initial states, comics, characters and query filters
  const { id } = useParams();
  const url = `${process.env.REACT_APP_API}`;
  const token = sessionStorage.getItem('token');
  const [isLoading, setLoading] = useState(true);
  const [count, setCount] = useState();
  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useState({
    country: '',
    searchStr: '',
    isValidated: true,
    typeId: '',
    displayCount: 20,
    page: 1,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const location = useLocation();
  const companyName = location.state && location.state.companyName;

  //Get all resgistered users to display -------------\\
  useEffect(() => {
    const getUsers = async () => {
        const { country, searchStr, isValidated, typeId, displayCount, page } =
        filters;
      // Get users on page load..
      const response = await axios.get(
        `${url}/admin/users/company/${id}?country=${country}&searchStr=${searchStr}&isValidated=${isValidated}&typeId=${typeId}&displayCount=${displayCount}&page=${page}`,
        { headers: { authorization: `Bearer ${token}` } }
      );
      const [count, users] = response.data;
      setUsers(users);
      setCount(count);

      setLoading(false);
    };

    //Check if user is logged in with a valid token
    isLoggedIn(navigate, dispatch, '/users');
    const timeoutId = setTimeout(() => {
      getUsers();
    }, 300);

    return () => {
      clearTimeout(timeoutId);
      setLoading(false);
    };
  }, [filters]);

  // Component return .. .. ;;
  return isLoading ? (
    // Waiting data loading after async request...
    <>
      <Helmet>
        <title>Badgers SAS: Registered users</title>
        <meta name='page' content='registered users' />
      </Helmet>
      <div className='loading'>
        <img src='/img/loading.gif' alt='Loading' className='loading-img' />
      </div>
    </>
  ) : (
    //All users .. map and render user table
    <>
      <Helmet>
        <title>Badgers SAS: Registered users</title>
        <meta name='page' content='registered users' />
      </Helmet>
      <Navbar displayMob={true} />
      <div className=''>
        <UserFilter
          count={count}
          filters={filters}
          setFilters={setFilters}
          displayMob={true}
        />
        <div className='container'>
          <div>
            <Link className='page-links' to='/'>
              <FontAwesomeIcon icon='home' />{' '}
            </Link>
            <span className='link-span'>
              <FontAwesomeIcon icon='angle-right' />
            </span>
            <Link className='page-links' to={`/company/${id}`}>
              <span className='link-span'>{companyName.toUpperCase()}</span>
            </Link>
            <span>
              <FontAwesomeIcon icon='angle-right' />{' '}
            </span>
            <span>USERS</span>
          </div>

          <div className='container'>
            <div className='col-12 d-flex justify-content-center my-4'>
              <h4 className='h4'>
                {`${companyName}'s Users `}
                <FontAwesomeIcon icon='user' />
              </h4>
            </div>

            <table className='table table-striped table-hover'>
              <thead>
                <tr className={`${styles.headings}`}>
                  <th scope='col'>#</th>
                  <th scope='col'>First Name</th>
                  <th scope='col'>Surname</th>
                  <th scope='col'>Level</th>
                  <th scope='col'>Email</th>
                  <th scope='col'>Tel</th>
                  <th scope='col'>Country</th>
                  <th scope='col'>City</th>
                  <th scope='col'>Company</th>
                  <th scope='col'>Designation</th>
                  <th scope='col'>Validated??</th>
                  <th scope='col'>Created at</th>
                  <th scope='col'>Updated at</th>
                </tr>
              </thead>
              <tbody>
                {users.length > 0 ? (
                  users.map((user, idx) => {
                    return (
                      <tr
                        key={user.id}
                        className={`${styles.rows} ${
                          !user.validated && 'table-danger'
                        }`}
                        onClick={() => navigate(`/user/${user.id}`)}
                      >
                        <th scope='row'>{idx + 1}</th>
                        <td>{user.prenom}</td>
                        <td>{user.nom}</td>
                        <td>{user.type}</td>
                        <td>{user.email}</td>

                        <td>{user.contact}</td>
                        <td>{user.pays}</td>
                        <td>{user.ville}</td>
                        <td>{user.entreprise}</td>
                        <td>{user.fonction}</td>
                        <td>{user.validated ? 'Yes' : 'No'}</td>
                        <td>
                          {new Date(user.createdAt).toLocaleString('en-UK', {
                            dateStyle: 'short',
                            timeStyle: 'short',
                          })}
                        </td>
                        <td>
                          {new Date(user.updatedAt).toLocaleString('en-UK', {
                            dateStyle: 'short',
                            timeStyle: 'short',
                          })}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={13}>No users to display ...</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <FooterProfile />
      </div>
    </>
  );
};

export default CompanyUsers;
