// useApi.js
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import isLoggedIn from '../../utils/isLoggedIn';

const useCompany = (token, id) => {
  const url = `${process.env.REACT_APP_API}/admin/company/${id}`;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState(null);

  const fetchCompany = () => {
    axios
      .get(`${url}`, {
        headers: { authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setLoading(false);
        setCompany(response.data);
      });
  };

  useEffect(() => {
    // check if user is logged in
    isLoggedIn(navigate, dispatch, `/company/update/${id}`);
    fetchCompany();
  }, [id]);

  return { loading, company };
};

export default useCompany;
