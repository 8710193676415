import { useParams, Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import toast, { Toaster } from 'react-hot-toast';
import styles from './../pages/product.module.css';
import axios from 'axios';
import CartDetails from '../components/cart_details';
import ClientDetails from '../components/client_details';
import Articles from '../components/articles';
import Navbar from '../components/navbar';
import isLoggedIn from '../utils/isLoggedIn';

const Cart = () => {
  const { id } = useParams();
  const url = `${process.env.REACT_APP_API}/admin/cart/${id}`;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [cart, setCart] = useState(); //Product details state
  const [user, setUser] = useState(); //Client details state
  const [delivery, setDelivery] = useState(); //Client details state
  const [articles, setArticles] = useState([]); //Product details state
  const [isloading, setLoading] = useState(true); //Loading isloading toggle

  const token = sessionStorage.getItem('token');

  // Query to get order data
  useEffect(() => {

    isLoggedIn(navigate, dispatch,`/store/cart/${id}`);

    let active = true;

    (async () => {
      const response = await axios(url, {
        headers: { authorization: `Bearer ${token}` },
      });
      if (active) {
        const { shippedBy, shippingCode: productCode, country, city, postcode, address } = response.data;
        const { User, articles } = response.data;
        setCart(response.data);
        setUser(User);
        setArticles(articles);
        setDelivery({
          shippedBy,
          productCode,
          country,
          city,
          postcode,
          address,
        });
        setLoading(false);
      }
    })();
    return () => {
      active = false;
      setCart(null);
      setArticles([]);
      setUser(null);
      setDelivery(null)
    };
  }, [id, token]);

  //handle listing valitaion

  // -----------------------------------------------------------------------------

  return isloading ? (
    // Waiting data loading after async request...
    <>
      <Helmet>
        <title>Badgers SAS: Cart details</title>
        <meta name='page' content='cart details' />
      </Helmet>
      <div className='loading'>
        <img src='/img/loading.gif' alt='Loading' className='loading-img' />
      </div>
    </>
  ) : (
    <>
      <Helmet>
        <title>Badgers SAS: Cart details</title>
        <meta name='page' content='cart details' />
      </Helmet>
      <Navbar displayMob={true} />
      <div className={`${styles.background} container`}>
        <Toaster position='top-center' reverseOrder={false} />
        {/* ------------Navlinks----------- */}
        <div className=''>
          <Link className='page-links' to='/'>
            <FontAwesomeIcon icon='home' />{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <Link className='page-links' to='/store/carts'>
            CARTS{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <span className='link-span'>{cart.id}</span>
        </div>
        {/* ----------------------- */}
        <div className='col-12 d-flex justify-content-center my-4'>
          <h4 className='h4'>
            CART DETAILS <FontAwesomeIcon icon='shopping-basket' />
          </h4>
        </div>
        <div className={`${styles.card} row g-4 my-3 pb-3`}>
          <CartDetails cart={cart} />
          <ClientDetails user={user} delivery={delivery} />
        </div>
        <Articles articles={articles} currency={cart.currency} />
      </div>
    </>
  );
};

export default Cart;
