import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useEffect, useState, React } from 'react';
import { useForm } from 'react-hook-form';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import cats from '../data/categories.json';
import countries from '../data/countries.json';
import styles from './listingform.module.css';

export const ListingForm = ({user, token}) => {
  // Initial state and variables ---------------------------------------------------\\

  const url = `${process.env.REACT_APP_API}/api/`;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const categories = Object.keys(cats);
  const [choice, setChoice] = useState('');
  const [image, setPostImage] = useState([]);
  const [fileNumber, setNumber] = useState(0);
  const [fileSize, setsize] = useState(0);
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [types, setTypes] = useState([]);
  const handleClose = () => {
    setOpen(false);
  };


  // UseEffects, network calls ---------------------------------------------------\\

  useEffect(() => {
    if (error) {
      toast.error('Sorry something went wrong. Please try again!', {
        duration: 4000,
      });
      setSpinner(false);
    }
  }, [error]);

  // Add upload success handler ---------------------------------------------------\\

  const upLoadSuccess = () => {
    reset();
    resetFiles();
    setSpinner(false);
    setOpen(true);
  };

  // Reset Attached files ---------------------------------------------------\\

  const resetFiles = () => {
    setPostImage([]);
    setNumber(0);
    setsize(0);
  };

  // Compose headers ---------------------------------------------------\\

  const myHeaders = new Headers();
  myHeaders.append('Accept', 'application/json');
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${token}`);

  // File change/select hanlder ---------------------------------------------------\\

  const handleFileChange = (e) => {
    let name = '';
    let files = [];
    let size = 0;
    setNumber(e.target.files.length);
    for (let i = 0; i < e.target.files.length; i++) {
      name = e.target.files[e.target.files.length - 1].name;
      files.push(e.target.files[i]);
      size += e.target.files[i].size;
    }
    setsize(size);
    if (e.target.files.length <= 6 && size < 20971520) {
      setPostImage(files);
    } else if (e.target.files.length > 6) {
      toast.error(
        "You can't upload more than 6 images per equipment/product!",
        {
          duration: 4000,
        }
      );
      resetFiles();
    } else if (size > 20971520) {
      toast.error("Total image size shouldn't exceed 20MB.", {
        duration: 4000,
      });
      resetFiles();
    }
  };

  // Post sale handler ---------------------------------------------------\\

  function postVente(materielId) {
    let today = new Date().toISOString();
    let data = {
      userId: parseInt(user.userId),
      materielId: parseInt(materielId),
      dateVente: today,
      statut: 1,
    };
    var raw = JSON.stringify(data);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };
    fetch(url + 'ventes', requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          let res = response.text();
          res.then((data) => {
            var rslt = JSON.parse(data);
            setError(rslt);
          });
          throw new Error('something went wrong');
        }
      })
      .then((result) => {
        var rslt = JSON.parse(result);
      })
      .catch((error) => {
        console.log(error.message);
        setError(error.message);
      });
  }

  // Update stock handler ---------------------------------------------------\\

  function poststock(materielId, qty) {
    let data = {
      materielId: materielId,
      quantite: parseInt(qty),
    };
    var raw = JSON.stringify(data);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };
    return fetch(url + 'stocks', requestOptions).then((response) => {
      if (response.ok) {
        return response.text();
      } else {
        let res = response.text();
        res.then((data) => {
          var rslt = JSON.parse(data);
          setError(rslt);
        });
        throw new Error('Something went wrong while updating stock.');
      }
    });
  }

  // Upload product images handler ---------------------------------------------------\\

  async function postImages(materielId) {
    var headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);
    var imageData = new FormData();
    imageData.append('materielId', materielId);
    image.forEach((file) => {
      imageData.append('image', file, `${__dirname}/${file.name}`);
    });
    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: imageData,
      redirect: 'follow',
    };

    return fetch(url + 'image', requestOptions).then((response) => {
      if (response.ok) {
        // setError('success');
        upLoadSuccess();
      } else {
        let res = response.text();
        res.then((data) => {
          var rslt = JSON.parse(data);
          setError(rslt);
        });
        throw new Error('Something went wrong while uploading images.');
      }
    });
  }

  // Materiel registration/upload handler ---------------------------------------------------\\

  function postmateriel(collection, qty) {
    var raw = JSON.stringify(collection);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };
    fetch(url + 'materiels', requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          let res = response.text();
          res.then((data) => {
            var rslt = JSON.parse(data);
            setError(rslt);
          });
          throw new Error('Something went wrong while saving product details.');
        }
      })
      .then(async (response) => {
        const { materielId } = JSON.parse(response);
        return poststock(materielId, qty);
        // postVente(rslt.id);
      })
      .then(async (response) => {
        const { materielId } = JSON.parse(response);
        postImages(materielId);
      })
      .catch((error) => {
        setError(error.message);
      });
  }

  // Form submit handler ---------------------------------------------------\\
  const onSubmit = (data) => {
    if (!spinner) {
      setSpinner(true);
    }
    if (image.length && fileSize < 20971520 && fileNumber <= 6) {
      const {
        title,
        brand,
        categorie,
        type,
        description,
        country,
        city,
        prix,
        currency,
        condition,
        owner,
        qty,
      } = data;
      const legalOwner = choice === 'autre' ? owner : choice;
      const entrepriseId = user?.userEntrepriseId;

      const product = {
        title,
        brand,
        categorie,
        type,
        description,
        country,
        city,
        prix,
        currency,
        condition,
        legalOwner,
        entrepriseId,
      };
      postmateriel(product, qty);
    } else {
      toast.error(
        'Please upload at least one image and a maximum of 6 images, total size not exceeding 20 MB.',
        {
          duration: 4000,
        }
      );
      setSpinner(false);
    }
  };

  // Category select handling, to get dynamic types ---------------------------------------------------\\

  const catChange = (evt) => {
    const types = cats[evt.target.value];
    if (types) {
      setTypes(types);
    }
  };

  return (
    <div className={styles.pan}>
      <Toaster position='top-center' reverseOrder={false} />

      <form className='row g-3' onSubmit={handleSubmit(onSubmit)}>
        {/* ----------------------- */}
        <div className=''>
          <Link className='page-links' to='/'>
            <FontAwesomeIcon icon='home' />{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <span className='link-span'>Product Listing</span>
        </div>
        {/* ----------------------- */}
        <div className='col-12 d-flex justify-content-center my-4'>
          <h4 className='h4'>
            List Your Product <FontAwesomeIcon icon='bullhorn' />
          </h4>
        </div>

        {/* ----------------------------------------------- */}

        <div className={`${styles.card} row mb-2 sellfirst-zone`}>
          <div className='mb-3 col-12'>
            <label htmlFor='title' className='form-label'>
              Title of Item
            </label>
            <input
              type='text'
              id='title'
              name='title'
              placeholder='Choose a brief title for your listing.'
              className='form-control'
              {...register('title', {
                required:
                  'Please enter a brief title for your product listing.',
                maxLength: 50,
              })}
            />
            {errors?.title && (
              <p className='help-block text-danger my-2'>
                {errors.title?.message}
              </p>
            )}
          </div>
          <div className='mb-3 col-12'>
            <label htmlFor='brand' className='form-label'>
              Manufacturer, Brand
            </label>
            <input
              type='text'
              id='brand'
              name='brand'
              placeholder='Please enter product manufacturer or brand name.'
              className='form-control'
              {...register('brand', {
                required: 'Please product manufacturer or brand.',
              })}
            />
            {errors?.brand && (
              <p className='help-block text-danger my-2'>
                {errors.brand?.message}
              </p>
            )}
          </div>
          <div className='col-12'>
            <label htmlFor='description' className='form-label'>
              Description
            </label>
            <textarea
              name='description'
              placeholder='Please enter a detailed description of your product, equipment.'
              id='description'
              className='form-control'
              rows='4'
              {...register('description', {
                required:
                  'Please enter a detailed description of your product.',
                maxLength: 2500,
              })}
            ></textarea>
            {errors?.description && (
              <p className='help-block text-danger my-2'>
                {errors.description?.message}
              </p>
            )}
          </div>
        </div>

        {/* ----------------------------------------------- */}

        <div className={`${styles.card} row mb-2 sellsecond-zone`}>
          <div className='my-2 col-md-6'>
            <label htmlFor='categorie' className='form-label'>
              Category
            </label>
            <select
              aria-label='categorie'
              id='categorie'
              name='categorie'
              onSelect={(evt) => catChange(evt)}
              className='form-select form-control'
              {...register('categorie', {
                onChange: catChange,
                required: 'Product category is required',
                minLength: 3,
              })}
            >
              <option className='default-select' value=''>
                Pickup a product category
              </option>
              {categories.map((d, idx) => (
                <option value={d} key={idx}>
                  {d}
                </option>
              ))}
            </select>
            {errors?.categorie && (
              <p className='help-block text-danger my-2'>
                {errors.categorie?.message}
              </p>
            )}
          </div>
          <div className='my-2 col-md-6'>
            <label htmlFor='type' className='form-label'>
              Type
            </label>
            <select
              aria-label='type'
              id='type'
              name='type'
              className='form-select'
              {...register('type', {
                required:
                  'Please choose a corresponding product type or select miscellaneous.',
                minLength: 3,
              })}
            >
              <option className='default-select' value=''>
                Pickup Equipment/Commodity Type
              </option>
              {types.length &&
                types.map((d, idx) => (
                  <option value={d.type} key={idx}>
                    {d}
                  </option>
                ))}
              <option className='default-select' value='Miscellaneous'>
                Miscellaneous
              </option>
            </select>
            {errors?.type && (
              <p className='help-block text-danger my-2'>
                {errors.type?.message}
              </p>
            )}
          </div>

          <div className='my-2 col-md-6'>
            <label htmlFor='condition' className='form-label'>
              Condition
            </label>
            <select
              id='condition'
              name='condition'
              className='form-select form-control'
              {...register('condition', {
                required: 'Tell us about equipment condition.',
                minLength: 3,
              })}
            >
              <option className='default-select' value=''>
                Choose equipment condition
              </option>
              <option className='default-select' value='used'>
                used
              </option>
              <option value='new'>new</option>
            </select>
            {errors?.condition && (
              <p className='help-block text-danger my-2'>
                {errors.condition?.message}
              </p>
            )}
          </div>
          <div className='my-2 col-md-6'>
            <label htmlFor='qty' className='form-label'>
              Quantity
            </label>
            <input
              type='number'
              id='qty'
              name='qty'
              className='form-control'
              {...register('qty', {
                required: 'Quantity is required.',
                min: {
                  value: 1,
                  message: 'Please a enter a valid quantity.',
                },
                max: {
                  value: 5000000,
                  message: 'Quantity exceeds the maximum allowed.',
                },
              })}
            />
            {errors?.qty && (
              <p className='help-block text-danger my-2'>
                {errors.qty?.message}
              </p>
            )}
          </div>
          <div className='my-2 col-md-6'>
            <label htmlFor='prix' className='form-label'>
              Unit Price
            </label>
            <input
              type='number'
              id='prix'
              name='prix'
              className='form-control'
              {...register('prix', {
                required: 'Unit price is required.',
                min: {
                  value: 1,
                  message: 'Please a enter a valid price.',
                },
                max: {
                  value: 999000000,
                  message: 'Price figure exceeds the allowed amount.',
                },
              })}
            />
            {errors?.prix && (
              <p className='help-block text-danger my-2'>
                {errors.prix?.message}
              </p>
            )}
          </div>

          <div className='my-2 col-md-6'>
            <label htmlFor='' className='my-2 form-label'>
              Currency:
            </label>
            <div>
              <div className='form-check form-check-inline'>
                <input
                  {...register('currency', {
                    required: 'Please a select a currency, dollar or euro.',
                  })}
                  type='radio'
                  id='usd'
                  name='currency'
                  value='usd'
                  className='form-check-input'
                />
                <label htmlFor='usd' className='form-check-label'>
                  US$
                </label>
              </div>
              <div className='form-check form-check-inline'>
                <input
                  {...register('currency', {
                    required: 'Please a select a currency, dollar or euro.',
                  })}
                  type='radio'
                  id='eur'
                  name='currency'
                  value='eur'
                  className='htmlForm-check-input'
                />
                <label htmlFor='eur' className='htmlForm-check-label'>
                  Euro €
                </label>
              </div>
              {errors?.currency && (
                <p className='help-block text-danger my-2'>
                  {errors.currency?.message}
                </p>
              )}
            </div>
          </div>

          <div className='my-2 col-md-6'>
            <label htmlFor='country' className='form-label'>
              Country, Location
            </label>
            <select
              id='type'
              name='type'
              className='form-select form-control'
              {...register('country', {
                required:
                  'Please select a country from the list, where product is located.',
                minLength: 3,
              })}
            >
              <option className='default-select' value=''>
                Select Product Location
              </option>
              {countries.map((obj, idx) => (
                <option value={obj.name} key={idx}>
                  {obj.name}
                </option>
              ))}
            </select>
            {errors?.country && (
              <p className='help-block text-danger my-2'>
                {errors.country?.message}
              </p>
            )}
          </div>

          <div className='my-2 col-md-6'>
            <label htmlFor='city' className='form-label'>
              City, State
            </label>
            <input
              type='text'
              id='city'
              name='city'
              className='form-control'
              {...register('city', {
                required:
                  'Tell us in which city or state, the product is located.',
                minLength: 3,
              })}
            />
            {errors?.city && (
              <p className='help-block text-danger my-2'>
                {errors.city?.message}
              </p>
            )}
          </div>

          <div className='my-2 col-12'>
            <label htmlFor='legal' className='form-label'>
              Legal Entity Owner
            </label>
            <select
              id='legal'
              name='legal'
              className='form-select form-control'
              onChange={(e) => setChoice(e.target.value)}
            >
              <option className='default-select' value=''>
                Specify the legal owner of the equipment.
              </option>
              {user ? (
                <option value={user.userEntreprise}>
                  {user.userEntreprise}
                </option>
              ) : (
                ''
              )}
              <option value='other'>Other</option>
            </select>
            {errors?.owner && (
              <p className='help-block text-danger my-2'>
                {errors.owner?.message}
              </p>
            )}
          </div>

          {choice === 'other' && (
            <div className='my-2 col-12'>
              <label htmlFor='owner' className='form-label'>
                Specify Legal Owner
              </label>
              <input
                type='text'
                id='owner'
                name='owner'
                className='form-control'
                {...register('owner')}
              />
              <p className='help-block text-danger my-2'>
                {errors.owner?.message}
              </p>
            </div>
          )}
        </div>
        {/* ----------------------------------------------- */}
        <div className={`${styles.card} row sellthird-zone`}>
          <div className='input-group mb-3'>
            <label className='input-group-text' htmlFor='image'>
              Attach Product Pictures
            </label>
            <input
              id='image'
              name='image'
              type='file'
              multiple
              accept='.jpg, .jpeg, .png'
              onChange={(e) => handleFileChange(e)}
              className='form-control'
              maxfilesize={20971520}
            />
            {fileSize ? (
              <button className='btn btn-danger' onClick={resetFiles}>
                Reset Files
              </button>
            ) : (
              ''
            )}
          </div>
          <div>
            {errors?.image && (
              <p className='help-block text-danger my-2'>
                {errors.image?.message}
              </p>
            )}
            {image && (
              <p className='my-2'>{`${fileNumber} file(s) attached.`}</p>
            )}
            <p className='my-2'>
              Please attach atleast one image and upto six images, not exceeding
              20MB in size.
            </p>
          </div>

          {!spinner && (
            <div className='d-grid gap-2 my-3'>
              <button type='submit' className={`btn ${styles.btnsubmit}`}>
                Submit Listing {'  '}
                <FontAwesomeIcon icon='upload' />
              </button>
            </div>
          )}
          {spinner && (
            <div className='d-grid gap-2 my-3'>
              <button className={`btn ${styles.btnsubmit}`}>
                Sending data...
                <div
                  className='spinner-grow spinner-grow-sm'
                  role='status'
                ></div>
              </button>
            </div>
          )}
        </div>

        {/* ----------------------------------------------- */}

        <div>
          <Dialog onClose={handleClose} open={open}>
            <DialogContent dividers>
              <img
                src='/img/success-icon-23194.png'
                alt='sell-eqpt success'
                style={{
                  width: '20%',
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              />
              <h3>
                Thanks for trust in Badgers. Your product listing was
                successfully uploaded, We will get back to you shortly.
              </h3>
            </DialogContent>
            <DialogActions>
              <Button
                variant='outlined'
                autoFocus
                onClick={handleClose}
                style={{ marginRight: 10 }}
              >
                Add Another Listing
              </Button>
              <Link to='/listings'>
                <Button variant='outlined' autoFocus onClick={handleClose}>
                  Listings
                </Button>
              </Link>
            </DialogActions>
          </Dialog>
        </div>
      </form>
    </div>
  );
};
