import axios from "axios";

const getFavs = async (token) => {

      const url = `${process.env.REACT_APP_API}/api`;
      const favs = await axios.get(`${url}/favs`, {
        headers: { authorization: `Bearer ${token}` },
      });
    
      return favs;
}

export default getFavs;