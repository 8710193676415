import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FooterProfile from '../components/footer_profile';
import UsersByCountry2 from '../components/analytics/users_by_country2';
import UsersByCountry from '../components/analytics/users_by_country';
import ViewsByLocation from '../components/analytics/views_by_location';
import SessionsByChannel from '../components/analytics/sessions_by-channel';
import UserEngagement from '../components/analytics/user_engagement';
import PageHits from '../components/analytics/page_hits';
import Navbar from '../components/navbar';
import isLoggedIn from '../utils/isLoggedIn';

const Analytics = () => {
  //set initial states,
  const [history, setHistory] = useState('30');
  const { token } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch()

  // UseEffects, check if there's a user and token ---------------------------------------------------\\
    useEffect(() => {
      isLoggedIn(navigate, dispatch, '/analytics');
    }, []);


  //conver seconds to minuts
  function convertToMinutes(seconds) {
    let minutes = ~~(seconds / 60);
    let extraSeconds = seconds % 60;

    return `${minutes}m ${extraSeconds}s`;
  }

  // Component return .. ..;
  return (
    <>
      <Helmet>
        <title>Badgers SAS: Stats and analytics</title>
        <meta name='page' content='Stats and analytics' />
      </Helmet>
      <Navbar displayMob={true} />
      <div className=''>
        <div className='container'>
          <div>
            <Link className='page-links' to='/'>
              <FontAwesomeIcon icon='home' />{' '}
            </Link>
            <span className='link-span'>
              <FontAwesomeIcon icon='angle-right' />
            </span>
            <span className='link-span'>STATS AND ANALYTICS</span>
          </div>

          <div className='container'>
            <div className='col-12 d-flex justify-content-center my-4'>
              <h4 className='h4'>
                STATS & ANALYTICS <FontAwesomeIcon icon='chart-line' />
              </h4>
            </div>
            {/* -----------------------------Visits by Country ---------------------------------------*/}
            <div className='d-flex justify-content-between'>
              <h5 className='h5'>Platform Visits by Country</h5>
              <div className='pages'>
                <span>Last</span>
                <select
                  defaultValue={history}
                  name='results-per-page'
                  id='results-per-page'
                  onChange={(evt) => setHistory(evt.target.value)}
                >
                  <option value='3'>3</option>
                  <option value='7'>7</option>
                  <option value='10'>10</option>
                  <option value='14'>14</option>
                  <option value='28'>28</option>
                  <option value='30'>30</option>
                  <option value='45'>45</option>
                  <option value='60'>60</option>
                  <option value='90'>90</option>
                </select>
                <span>days</span>
              </div>
            </div>

            <hr />
            <ViewsByLocation
              history={history}
              location={'country'}
              caption={'Visit Stats by Country'}
            />
            {/* -----------------------------Total Users by Country ---------------------------------------*/}
            <h5 className='h5'>Total Users by Country</h5>
            <hr />
            <UsersByCountry2 history={history} />
            {/* -----------------------------Page views by Country ---------------------------------------*/}
            <h5 className='h5'>Users Evolution by Country</h5>
            <hr />
            <UsersByCountry history={history} />
            {/* -----------------------------Page views by city ---------------------------------------*/}
            <h5 className='h5'>Visit Stats by City</h5>
            <hr />
            <ViewsByLocation
              history={history}
              location={'city'}
              caption={'Visit Stats by City'}
            />
            {/* -----------------------------Users Engagement ---------------------------------------*/}
            <h5 className='h5'>User Engagement Aggregations</h5>
            <hr />
            <UserEngagement history={history} />
            {/* ----------------------------- Product/page Hits ---------------------------------------*/}
            <h5 className='h5'>Product Engagement Metrics</h5>
            <hr />
            <PageHits history={history} />
            {/* ----------------------------- Sessions by Channel ---------------------------------------*/}
            <h5 className='h5'>Traffic Acquisition Channels</h5>
            <hr />
            <SessionsByChannel history={history} token={token} />
          </div>
        </div>

        <FooterProfile />
      </div>
    </>
  );
};

export default Analytics;
