import styles from './../pages/product.module.css';
import React from 'react';

const ClientDetails = ({ user, delivery }) => {
  return (
    <>
      {/* ------------Product images carrousel end----------- */}
      <div className='product-block col-md-6'>
        <h4 className='h5'>{`Buyer: ${user.prenom?.toUpperCase()} ${user.nom?.toUpperCase()}`}</h4>
        <h5 className='h6'>{`${user.ville}, ${user.pays}`}</h5>
        <p className='product-subheads product-type mb-2 mt-3'>
          <span className={styles.label}>Email:</span>
          <span className={styles.value}>{user.email}</span>
        </p>
        <p className='product-subheads product-type mb-2'>
          <span className={styles.label}>Tel:</span>
          <span className={styles.value}>{user.contact}</span>
        </p>
        <p className='product-subheads product-brand mb-2'>
          <span className={styles.label}>Company:</span>
          <span className={styles.value}>{user.entreprise}</span>
        </p>

        <h5 className='h5 mt-4'>Shipping Address:</h5>
        <p className='product-subheads product-type mb-2'>
          <span className={styles.label}>Shipped by:</span>
          <span
            className={styles.value}
          >{`${delivery.shippedBy}, Product Code: ${delivery.productCode}`}</span>
        </p>
        {delivery.trackingId ? (
          <p className='product-subheads product-brand mb-2'>
            <span className={styles.label}>Tracking ID:</span>
            <span className={styles.value}>{delivery.trackingId}</span>
          </p>
        ) : undefined}
        {delivery.address ? (
          <p className='product-subheads product-brand mb-2'>
            <span className={styles.label}>Street Address:</span>
            <span className={styles.value}>{delivery.address}</span>
          </p>
        ) : undefined}
        <p className='product-subheads product-brand mb-2'>
          <span className={styles.label}>City:</span>
          <span className={styles.value}>{delivery.city}</span>
        </p>
        <p className='product-subheads product-brand mb-2'>
          <span className={styles.label}>Postcode:</span>
          <span className={styles.value}>{delivery.postcode}</span>
        </p>
        <p className='product-subheads product-brand mb-2'>
          <span className={styles.label}>Country:</span>
          <span className={styles.value}>{delivery.country}</span>
        </p>
      </div>
    </>
  );
};

export default ClientDetails;
