  //map data and relative keys

  const formatDate = (dt) => {
    const dateString = dt.toString()
    return `${dateString.slice(0, 4)}-${dateString.slice(
      4,
      6
    )}-${dateString.slice(6, 8)}`;
  }


  const dataMapper = (input) =>
    input.map((row) => {
      return {
        name: row.dimensionValues[0].value,
        date: new Date(formatDate(row.dimensionValues[1].value)),
        visits: parseInt(row.metricValues[0].value),
      };
    });

  const getCountries = (array) => {
    const countries = [];
    array.forEach((element) => {
      !countries.includes(element.name) && countries.push(element.name);
    });
    return countries;
  };

  const serializeData = (input) => {
    const rows = dataMapper(input);
    const countries = getCountries(rows);
    
    const serialized = [];

    for (let country of countries) {
      const filteredcountry = { name: country, data: [] };
      for (let row of rows) {
        const { name, date, visits } = row;
        (name === country) && filteredcountry.data.push({ date, visits });
      }
      serialized.push(filteredcountry);
    }

    return serialized;
  };

  export default serializeData;