//Truncate .shorten names..text...
exports.truncate = (str, length) => {
  if (str.length > length) {
    return str.substring(0, length) + '..';
  }
  return str;
};


  //Which favourite icon to display
  exports.favIcon = (favs, field, elem) => {
    if (favs === null || favs === undefined) {
      return false;
    } else if (favs[field] && favs[field].indexOf(elem._id) !== -1) {
      return true;
    }
    return false;
  };

  exports.orderStatus = (code) => {
    switch (code) {
      case 1:
        return 'confirmed';
      case 2:
        return 'Under verification';
      case 3:
        return 'Cancelled';
      case 4:
        return 'Under packaging';
      case 5:
        return 'Ready to be shipped';
      case 6:
        return 'Shipped, in transit';
      case 7:
        return 'Delivered';
    }
  };
