import styles from './../pages/product.module.css';
import React from 'react';

const UserDetails = ({ user }) => {
  return (
    <>
      {/* ------------Product images carrousel end----------- */}
      <div className='product-block col-md-6'>
        <h4 className='h4'>{`Sent By: ${user.prenom?.toUpperCase()} ${user.nom?.toUpperCase()}`}</h4>
        <h5 className='h5'>{`${user.ville}, ${user.pays}`}</h5>
        <p className='product-subheads product-type mb-2 mt-3'>
          <span className={styles.label}>Email:</span>
          <span className={styles.value}>{user.email}</span>
        </p>
        <p className='product-subheads product-type mb-2'>
          <span className={styles.label}>Tel:</span>
          <span className={styles.value}>{user.contact}</span>
        </p>
        <p className='product-subheads product-brand mb-2'>
          <span className={styles.label}>Company:</span>
          <span className={styles.value}>{user.entreprise}</span>
        </p>
        <p className='product-subheads product-brand mb-2'>
          <span className={styles.label}>Designation:</span>
          <span className={styles.value}>{user.fonction}</span>
        </p>
      </div>
    </>
  );
};

export default UserDetails;
