import styles from './../pages/product.module.css';
import axios from 'axios';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { orderStatus } from '../utils/utils';

const OrderDetails = ({ order, payment}) => {
  const [sid, setSID] = useState(order.statut);
  const { token } = useSelector((state) => state.user);

  // Update Order status handler ------------------------------------------------>>

  const updateOrderStatus = async () => {
    axios
      .put(
        `${process.env.REACT_APP_API}/admin/order/update/${order.id}`,
        { sid },
        {
          headers: { authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        if ((response.status = 200)) {
          toast.success(response?.data?.message, {
            duration: 3000,
          });
        }
      })
      .catch((err) => {
        toast.error(err.message, {
          duration: 3000,
        });
      });
  };


  return (
    <>
      <div className='product-block col-md-6'>
        <h5 className='h5'>{`Order ID: ${order.id}`}</h5>

        <div className=''>
          <button
            className={`${styles.edit} ${styles.btn}`}
            onClick={updateOrderStatus}
          >
            {`UPDATE STATUS  `}
            <FontAwesomeIcon icon='pen-square' />
          </button>
          <label htmlFor='processId' className='form-label my-3 mx-1'>
            SID
          </label>
          <select
            defaultValue={order.statut}
            onChange={(evt) => setSID(evt.target.value)}
            id='processId'
            name='processId'
            className={`form-select form-control d-inline ${styles.pid}`}
          >
            <option className='default-select' value='1'>
              1
            </option>
            <option className='default-select' value='2'>
              2
            </option>
            <option className='default-select' value='3'>
              3
            </option>
            <option className='default-select' value='4'>
              4
            </option>
            <option className='default-select' value='5'>
              5
            </option>
            <option className='default-select' value='6'>
              6
            </option>
            <option className='default-select' value='7'>
              7
            </option>
          </select>
        </div>

        <p className='product-subheads product-type mb-2'>
          <span className={styles.label}>Ordered on:</span>
          <span className={styles.value}>
            {new Date(order.createdAt).toLocaleString('en-UK', {
              dateStyle: 'long',
              timeStyle: 'short',
            })}
          </span>
        </p>
        <p className='product-subheads product-type mb-2'>
          <span className={styles.label}>Updated on:</span>
          <span className={styles.value}>
            {new Date(order.updatedAt).toLocaleString('en-UK', {
              dateStyle: 'long',
              timeStyle: 'short',
            })}
          </span>
        </p>
        <p className='product-subheads product-type mb-2 mt-3'>
          <span className={styles.label}>Sub-Total:</span>
          <span className={styles.value}>{`${parseFloat(payment.price).toFixed(
            2
          )} ${payment.currency === 'usd' ? 'US$' : '€'}`}</span>
        </p>
        <p className='product-subheads product-type mb-2'>
          <span className={styles.label}>Shipping:</span>
          <span className={styles.value}>{`${parseFloat(
            payment.shipping
          ).toFixed(2)} ${payment.currency === 'usd' ? 'US$' : '€'}`}</span>
        </p>
        <p className='product-subheads product-type mb-2'>
          <span className={styles.label}>Total Paid:</span>
          <span className={`${styles.value} fw-bold`}>{`${parseFloat(
            payment.total
          ).toFixed(2)} ${payment.currency === 'usd' ? 'US$' : '€'}`}</span>
        </p>
        <p className='product-subheads product-type mb-2 mt-4'>
          <span className={styles.label}>Order Status:</span>
          <span className={styles.value}>{orderStatus(order.statut)}</span>
        </p>
      </div>
    </>
  );
};

export default OrderDetails;
