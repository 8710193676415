import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import FooterProfile from '../components/footer_profile';
import styles from './listings.module.css';
import Navbar from '../components/navbar';
import CartFilter from '../components/cart_filter';
import isLoggedIn from '../utils/isLoggedIn';

const Carts = () => {
  //set initial states, comics, characters and query filters
  const url = `${process.env.REACT_APP_API}`;
  const token = sessionStorage.getItem('token');
  const [isLoading, setLoading] = useState(true);
  const [count, setCount] = useState();
  const [carts, setCarts] = useState([]);
  const [filters, setFilters] = useState({
    country: '',
    searchStr: '',
    displayCount: 20,
    page: 1,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch()

  //Get all products and equipments to display -------------\\
  useEffect(() => {
    const getRequests = async () => {
      const { searchStr, country, displayCount, page } = filters;
      // Get listings on page load..
      const response = await axios.get(
        `${url}/admin/carts?searchStr=${searchStr}&country=${country}&displayCount=${displayCount}&page=${page}`,
        { headers: { authorization: `Bearer ${token}` } }
      );
      const [count, carts] = response.data;
      setCarts(carts);
      setCount(count);
      setLoading(false);
    };

    //Check if user is logged in with a valid token
    isLoggedIn(navigate, dispatch, '/store/carts');
    const timeoutId = setTimeout(() => {
      getRequests();
    }, 300);

    return () => {
      clearTimeout(timeoutId);
      setLoading(false);
    };
  }, [filters]);

  // Component return .. .. ;
  return isLoading ? (
    // Waiting data loading after async request...
    <>
      <Helmet>
        <title>Badgers SAS:Abandoned orders</title>
        <meta name='page' content='abandoned carts' />
      </Helmet>
      <div className='loading'>
        <img src='/img/loading.gif' alt='Loading' className='loading-img' />
      </div>
    </>
  ) : (
    //All products .. map and render products table
    <>
      <Helmet>
        <title>Badgers SAS: Abandoned carts</title>
        <meta name='page' content='abandoned carts' />
      </Helmet>
      <Navbar displayMob={true} />
      <div className=''>
        <CartFilter
          count={count}
          filters={filters}
          setFilters={setFilters}
          displayMob={true}
        />
        <div className='container'>
          <div>
            <Link className='page-links' to='/'>
              <FontAwesomeIcon icon='home' />{' '}
            </Link>
            <span className='link-span'>
              <FontAwesomeIcon icon='angle-right' />
            </span>
            <Link className='page-links' to='/store'>
              STORE{' '}
            </Link>
            <span className='link-span'>
              <FontAwesomeIcon icon='angle-right' />
            </span>
            <span className='link-span'>CARTS</span>
          </div>

          <div className='container'>
            <div className='col-12 d-flex justify-content-center my-4'>
              <h4 className='h4'>
                ABANDONED CARTS <FontAwesomeIcon icon='shopping-basket' />
              </h4>
            </div>

            <table className='table table-striped table-hover'>
              <thead>
                <tr className={`${styles.headings}`}>
                  <th scope='col'>#</th>
                  <th scope='col'>Ship To</th>
                  <th scope='col'>User</th>
                  <th className='text-end' scope='col'>
                    Price
                  </th>
                  <th className='text-end' scope='col'>
                    VAT
                  </th>
                  <th className='text-end' scope='col'>
                    Shipping
                  </th>
                  <th className='text-end' scope='col'>
                    Total
                  </th>
                  <th scope='col'>Created at</th>
                  <th scope='col'>Updated at</th>
                </tr>
              </thead>
              <tbody>
                {carts.length > 0 ? (
                  carts.map((cart, idx) => {
                    return (
                      <tr
                        key={cart.id}
                        className={`${styles.rows}`}
                        onClick={() => navigate(`/store/cart/${cart.id}`)}
                      >
                        <th scope='row'>{idx + 1}</th>
                        <td>{cart.country}</td>
                        <td>{`${cart.User.prenom} ${cart.User?.nom}`}</td>
                        <td className='text-end'>{`${cart.price} ${
                          cart.currency === 'usd' ? 'US$' : '€'
                        }`}</td>
                        <td className='text-end'>{`${cart.vat} ${
                          cart.currency === 'usd' ? 'US$' : '€'
                        }`}</td>
                        <td className='text-end'>{`${cart.shipping} ${
                          cart.currency === 'usd' ? 'US$' : '€'
                        }`}</td>
                        <td className='text-end'>{`${cart.total} ${
                          cart.currency === 'usd' ? 'US$' : '€'
                        }`}</td>
                        <td>
                          {new Date(cart.createdAt).toLocaleString('en-UK', {
                            dateStyle: 'short',
                            timeStyle: 'short',
                          })}
                        </td>
                        <td>
                          {new Date(cart.updatedAt).toLocaleString('en-UK', {
                            dateStyle: 'short',
                            timeStyle: 'short',
                          })}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={11}>No carts to display ...</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <FooterProfile />
      </div>
    </>
  );
};

export default Carts;
