import { createSlice } from '@reduxjs/toolkit';

const initialElt =  {
    Eqpts:[],
    product:{}
}

const slice = createSlice({
    name: 'selectedEqpt',
    initialState: {
        selectedEqpt: initialElt,
    },
    reducers: {
        Selection: (state, action) => {
        state.selectedEqpt = action.payload;
        }
    },
});
export default slice.reducer;
export const { Selection } = slice.actions;