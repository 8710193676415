import { createSlice } from '@reduxjs/toolkit'

const initialqte =  {
    qte:1,
    commandeId:0,
}
const slice = createSlice({
    name: 'qte',
    initialState: {
        qte: initialqte,
    },
    reducers: {
        SetQte: (state, action) => {
        state.qte = action.payload;
        },
    },
});
export default slice.reducer;
  export const { SetQte } = slice.actions