import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ParseJwt from '../../tokenComponent';
import axios from 'axios';

const useAnalyticsReport = (token, report) => {
  const url = `${process.env.REACT_APP_API}`;
  const [isLoading, setLoading] = useState(true);
  const [stats, setStats] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const getReports = async () => {

      if (!token) {
        return navigate('/login', { state: { goTo: '/analytics' } });
      } else if (token) {
        const decodedJwt = ParseJwt(token);
        if (decodedJwt.exp * 1000 <= Date.now()) {
          return navigate('/login', { state: { goTo: '/analytics' } });
        }
      }

      // Get listings on page load..
      const response = await axios.post(
        `${url}/admin/analytics`,
        { report },
        { headers: { authorization: `Bearer ${token}` } }
      );
      setStats(response.data);
      setLoading(false);
    };

    getReports();

    return () => {
      setLoading(false);
    };
  }, [token, report]);

  return [stats, isLoading];
};

export default useAnalyticsReport;
