import React from 'react';
import {
  Tooltip,
  Legend,
  ResponsiveContainer,
  Pie,
  PieChart,
  Cell,
  LabelList
} from 'recharts';

const ChartSessionsByChannel = ({ data, caption }) => {
  const colors = [
    '#FF33FF',
    '#00B3E6',
    '#3366E6',
    '#99FF99',
    '#33991A',
    '#CC9999',
    '#B3B31A',
    '#00E680',
    '#4D8066',
    '#809980',
    '#E6FF80',
    '#1AFF33',
    '#999933',
  ];

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <PieChart width='100%' height='100%'>
        <Tooltip payload={data} />
        <Legend
          payload={[{ value: caption }]}
          verticalAlign='top'
          align='right'
        />
        <label htmlFor=''></label>
        <Pie
          nameKey={'channel'}
          data={data}
          dataKey='sessions'
          cx='50%'
          cy='50%'
          innerRadius={60}
          outerRadius={135}
          fill='#82ca9d'
        >
          <LabelList dataKey='channel' position='outside' stroke='#4D8066'/>
          <LabelList dataKey='sessions' position='inside' stroke='#ffffff' />
          {data.map((p, idx) => {
            return <Cell fill={colors[idx]} key={idx} />;
          })}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default ChartSessionsByChannel;
