import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {  useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setFeatured } from '../redux/featured';
import axios from 'axios';
import FooterProfile from '../components/footer_profile';
import ProductsTable from '../components/products_table';
import Navbar from '../components/navbar';
import isLoggedIn from '../utils/isLoggedIn';

const FeaturedListings = () => {
  //set initial states, comics, characters and query filters
  const url = `${process.env.REACT_APP_API}`;
  const token = sessionStorage.getItem('token');
  const [isLoading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Get all products and equipments to display -------------\\
  useEffect(() => {
    const getProducts = async () => {

      // Get listings on page load..
      const response = await axios.get(`${url}/admin/featured`, {
        headers: { authorization: `Bearer ${token}` },
      });
      const [featuredlist, products] = response.data;
      setProducts(products);
      dispatch(setFeatured(featuredlist));
      setLoading(false);
    };

    //check if user is logged in
    isLoggedIn(navigate, dispatch, '/featured');
    const timeoutId = setTimeout(() => {
      getProducts();
    }, 300);

    return () => {
      clearTimeout(timeoutId);
      setLoading(false);
    };
  }, []);

  // Component return .. .. COMIC or CHARACTER;;
  return isLoading ? (
    // Waiting data loading after async request...
    <>
      <Helmet>
        <title>Badgers SAS: featured listings</title>
        <meta name='page' content='featured listings' />
      </Helmet>
      <div className='loading'>
        <img src='/img/loading.gif' alt='Loading' className='loading-img' />
      </div>
    </>
  ) : (
    //All products .. map and render products table
    <div className=''>
      <Helmet>
        <title>Badgers SAS: featured listings</title>
        <meta name='page' content='featured listings' />
      </Helmet>
      <Navbar displayMob={true} />
      <div className='container'>
        <div>
          <Link className='page-links' to='/'>
            <FontAwesomeIcon icon='home' />{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <Link className='page-links' to='/listings'>
            LISTINGS{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <span className='link-span'>FEATURED LISTINGS</span>
        </div>

        <div className='container'>
          <div className='col-12 d-flex justify-content-center my-4'>
            <h4 className='h4'>
              Featured Listings <FontAwesomeIcon icon='thumbs-up' />
            </h4>
          </div>
          <ProductsTable products={products} />
        </div>
      </div>

      <FooterProfile />
    </div>
  );
};

export default FeaturedListings;
