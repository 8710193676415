import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ParseJwt from '../../tokenComponent';
import axios from 'axios';
import isLoggedIn from '../../utils/isLoggedIn';


const useAnalytics = (token, dimention, metric, startDate) => {
  const url = `${process.env.REACT_APP_API}`;
  const [isLoading, setLoading] = useState(true);
  const [stats, setStats] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch()

  useEffect(() => {
    const getReports = async () => {
      const report = {
        dimensions: [{ name: dimention }],
        metrics: [{ name: metric }],
        dateRanges: [{ startDate: startDate, endDate: 'yesterday' }],
        offset: 0,
        limit: 20,
        orderBys: [
          {
            metric: {
              metricName: metric,
            },
            desc: true,
          },
        ],
        metricAggregations: ['TOTAL'],
      };

      // Get listings on page load..
      const response = await axios.post(
        `${url}/admin/analytics`,
        { report },
        { headers: { authorization: `Bearer ${token}` } }
      );
      setStats(response.data);
      setLoading(false);
    };

    //Check if user is logged in with a valid token
    isLoggedIn(navigate, dispatch, '/analytics');
    getReports();

    return () => {
      setLoading(false);
    };
  }, [token, dimention, metric, startDate]);

  return [stats, isLoading];
};

export default useAnalytics;