import { createSlice } from '@reduxjs/toolkit';
// Slice

const featuredSlice = createSlice({
  name: 'featured',
  initialState: [],
  reducers: {
    setFeatured: (state, action) => {
      state.push(...action.payload);
    },

    addFeatured: (state, action) => {
      state.push(action.payload);
    },

    removeFeatured: (state, action) => {
      const idx = state.indexOf(action.payload);
      state.splice(idx, 1);
    },
  },
});
export default featuredSlice.reducer;
// Actions
export const { setFeatured, addFeatured, removeFeatured } = featuredSlice.actions;
