import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link, useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import Filter from '../components/filter';
import FooterProfile from '../components/footer_profile';
import styles from './listings.module.css';
import Navbar from '../components/navbar';
import isLoggedIn from '../utils/isLoggedIn';

const UserProducts = () => {
  //set initial states, comics, characters and query filters
  const { id } = useParams();
  const url = `${process.env.REACT_APP_API}`;
  const token = sessionStorage.getItem('token');
  const [isLoading, setLoading] = useState(true);
  const [count, setCount] = useState();
  const [products, setProducts] = useState([]);
  const filters = useSelector((state) => state.filters);
  const location = useLocation();
  const userName = location.state && location.state.userName;
  const navigate = useNavigate();
  const dispatch = useDispatch()

  //Get all products and equipments to display -------------\\
  useEffect(() => {
    const getProducts = async () => {

      const { categorie, searchStr, displayCount, isValidated, page } = filters;
      // Get listings on page load..
      const response = await axios.get(
        `${url}/admin/listings/user/${id}?categorie=${categorie}&searchStr=${searchStr}&displayCount=${displayCount}&isValidated=${isValidated}&page=${page}`,
        { headers: { authorization: `Bearer ${token}` } }
      );
      const [count, products] = response.data;
      setProducts(products);
      setCount(count);

      setLoading(false);
    };

    //check if user is logged ins
    isLoggedIn(navigate, dispatch, `$/listings/user/${id}`);
    const timeoutId = setTimeout(() => {
      getProducts();
    }, 300);

    return () => {
      clearTimeout(timeoutId);
      setLoading(false);
    };
  }, [filters]);

  // Component return .. .. COMIC or CHARACTER;;
  return isLoading ? (
    // Waiting data loading after async request...
    <>
      <Helmet>
        <title>Badgers SAS: user product listings</title>
        <meta name='page' content='user product listings' />
      </Helmet>
      <div className='loading'>
        <img src='/img/loading.gif' alt='Loading' className='loading-img' />
      </div>
    </>
  ) : (
    //All products .. map and render products table
    <>
      <Helmet>
        <title>Badgers SAS: user product listings</title>
        <meta name='page' content='user product listings' />
      </Helmet>
      <Navbar displayMob={true} />
      <div className=''>
        <Filter count={count} displayMob={true} />
        <div className='container'>
          <div>
            <Link className='page-links' to='/'>
              <FontAwesomeIcon icon='home' />{' '}
            </Link>
            <span className='link-span'>
              <FontAwesomeIcon icon='angle-right' />
            </span>
            <Link className='page-links' to='/users'>
              <span>USERS </span>
            </Link>
            <span className='link-span'>
              <FontAwesomeIcon icon='angle-right' />
            </span>
            <Link className='page-links' to={`/user/${id}`}>
              <span className='link-span'>{userName.toUpperCase()}</span>
            </Link>
            <span className='link-span'>
              <FontAwesomeIcon icon='angle-right' />
            </span>
            <span>LISTINGS</span>
          </div>

          <div className='container'>
            <div className='col-12 d-flex justify-content-center my-4'>
              <h4 className='h4'>
                {`${userName}'s Product Listings `}
                <FontAwesomeIcon icon='bullhorn' />
              </h4>
            </div>

            <table className='table table-striped table-hover'>
              <thead>
                <tr className={`${styles.headings}`}>
                  <th scope='col'>#</th>
                  <th scope='col'>Category</th>
                  <th scope='col'>Type</th>
                  <th scope='col'>Brand</th>
                  <th scope='col'>Price</th>
                  <th scope='col'>Condition</th>
                  <th scope='col'>Owner</th>
                  <th scope='col'>Country</th>
                  <th scope='col'>Region</th>
                  <th scope='col'>Validated</th>
                  <th scope='col'>Updated at</th>
                </tr>
              </thead>
              <tbody>
                {products.length > 0 ? (
                  products.map((product, idx) => {
                    return (
                      <tr
                        key={product.id}
                        className={`${styles.rows} ${
                          !product.validated && 'table-danger'
                        }`}
                        onClick={() => navigate(`/product/${product.id}`)}
                      >
                        <th scope='row'>{idx + 1}</th>
                        <td>{product.categorie}</td>
                        <td>{product.type}</td>
                        <td>{product.brand}</td>
                        <td>
                          {product.currency === 'usd' ? '$ ' : '€ '}
                          {Number(product.price).toLocaleString('en')}
                        </td>
                        <td>{product.condition}</td>
                        <td>{product.legalOwner}</td>
                        <td>{product.country}</td>
                        <td>{product.region}</td>
                        <td>{product.validated ? 'Yes' : 'No'}</td>
                        <td>
                          {new Date(product.updatedAt).toLocaleString('en-UK', {
                            dateStyle: 'short',
                            timeStyle: 'short',
                          })}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={11}>No Products to display ...</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <FooterProfile />
      </div>
    </>
  );
};

export default UserProducts;
