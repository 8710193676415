import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ParseJwt from '../../tokenComponent';
import axios from 'axios';

const useUsersByCountry = (token, dimention, startDate) => {
  const url = `${process.env.REACT_APP_API}`;
  const [isLoading, setLoading] = useState(true);
  const [viewsByLieu, setviewsByLieu] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const getReports = async () => {
      //Check if user is logged in with a valid token
      if (!token) {
        return navigate('/login', { state: { goTo: '/analytics' } });
      } else if (token) {
        const decodedJwt = ParseJwt(token);
        if (decodedJwt.exp * 1000 <= Date.now()) {
          return navigate('/login', { state: { goTo: '/analytics' } });
        }
      }

   const report = {
     dimensions: [{ name: dimention }],
     metrics: [
       { name: 'totalUsers' },
       { name: 'newUsers' },
       { name: 'engagedSessions' },
       { name: 'engagementRate' },
       { name: 'sessionsPerUser' },
       { name: 'userEngagementDuration' },
       { name: 'averageSessionDuration' },
       { name: 'eventCount' },
     ],
     dateRanges: [{ startDate: startDate, endDate: 'today' }],
     offset: 0,
     limit: 20,
     orderBys: [
       {
         metric: {
           metricName: 'totalUsers',
         },
         desc: true,
       },
     ],
     metricAggregations: ['TOTAL'],
   };

      // Get listings on page load..
      const response = await axios.post(
        `${url}/admin/analytics`,
        { report },
        { headers: { authorization: `Bearer ${token}` } }
      );
      setviewsByLieu(response.data);
      setLoading(false);
    };
    getReports();

    return () => {
      setLoading(false);
    };
  }, [token, dimention, startDate]);

  return [viewsByLieu, isLoading];
};

export default useUsersByCountry;
