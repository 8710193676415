import styles from './../pages/product.module.css';
import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';



const UserCard = ({ user }) => {
  const [confirmDelete, toggleConfirmDelete] = useState(false); //Loading isloading toggle
  const [type, setType] = useState(user.type);
  const [validated, setValidation] = useState(user.validated);
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.user);

  // Delete user  handler ------------------------------------------------>>

  const deleteUser = async () => {
    axios
      .delete(
        `${process.env.REACT_APP_API}/admin/user/${user.id}`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        if (response.status === 204) {
          toast.success(
            'User profile and associated data was successfully deleted from the database.',
            {
              duration: 3000,
            }
          );
          setTimeout(() => {
            navigate('/users');
          }, 4000);
        }
      })
      .catch((err) => {
        toast.error(
          `Error Code: ${err.response.status}. \n ${err.response.data.error}`,
          {
            duration: 3000,
          }
        );
      });
  };

  // Change User Type ------------------------------------------------>>
  const updateType = async () => {
    axios
      .patch(
        `${process.env.REACT_APP_API}/admin/user/type/${user.id}`,
        { type },
        {
          headers: { authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        if ((response.status = 200)) {
          toast.success(response?.data?.message, {
            duration: 3000,
          });
        }
      })
      .catch((err) => {
        toast.error(
          `Error Code: ${err.response.status}. \n ${err.response.data.error}`,
          {
            duration: 3000,
          }
        );
      });
  };

  //Handle validation status
  const handleIsValid = async () => {
    setValidation((validated) => !validated);

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API}/admin/user/validation/${user.id}`,
        { validated: !validated, type: type }, 
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.message, {
          duration: 4000,
        });
      } else {
        setValidation((validated) => !validated);
        toast.error(`Error Code: ${response.status}. \n ${response.data.message}`);
      }
    } catch (error) {
      setValidation((validated) => !validated);
      toast.error(
        `Error Code: ${error.response.status}. \n ${error.response.data.error}`
      );
      console.log(error.response.data.errors);
    }
  };

  //Handle unlink
  const handleUnlink = async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API}/admin/user/company/${user.id}`, {entrepriseId: null},
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.message, {
          duration: 3000,
        });
        setTimeout(() => {
          navigate(`/users`)
        }, 4000);

      } else {
        toast.error(`Error Code: ${response.status}. \n ${response.message}`);
      }
    } catch (error) {
      toast.error(
        `Error Code: ${error.response.status}. \n ${error.response.data.message}`
      );
      console.log(error.response.data.errors);
    }
  };

  //Delete confirmation button toggle handler
  const toggleDelete = () => {
    toggleConfirmDelete((state) => !state);
  };

  return (
    <>
      {/* ------------User Details----------- */}
      <div className='product-block col-md-6'>
        <Toaster position='top-center' reverseOrder={false} />
        <div className=''>
          <span className='h5'>{`${user.prenom?.toUpperCase()} ${user.nom?.toUpperCase()}`}</span>
          <FormControlLabel
            className='mb-1 mx-2'
            control={<Switch checked={validated} onChange={handleIsValid} />}
            label='Validation'
          />
        </div>
        <h5 className='h5'>{`${user.ville}, ${user.pays}`}</h5>
        <p className='product-subheads product-type mb-2 mt-3'>
          <span className={styles.label}>Email:</span>
          <span className={styles.value}>{user.email}</span>
          {user?.emailVerif ? (
            <span>
              <FontAwesomeIcon
                className={`${styles.verified} mx-2`}
                icon='check-circle'
              />{' '}
              Verified
            </span>
          ) : (
            <span>
              <FontAwesomeIcon
                className={`${styles.unverified} mx-2`}
                icon='question-circle'
              />{' '}
              Unverified
            </span>
          )}
        </p>
        <p className='product-subheads product-type mb-2'>
          <span className={styles.label}>Tel:</span>
          <span className={styles.value}>{user.contact}</span>
        </p>
        <p className='product-subheads product-brand mb-2'>
          <span className={styles.label}>Company:</span>
          <span className={styles.value}>{user.entreprise}</span>
        </p>
        <p className='product-subheads product-brand mb-2'>
          <span className={styles.label}>Designation:</span>
          <span className={styles.value}>{user.fonction}</span>
        </p>
        <p className='product-subheads product-brand mb-2'>
          <span className={styles.label}>CreatedAt:</span>
          <span className={styles.value}>
            {' '}
            {new Date(user.createdAt).toLocaleString('en-UK', {
              dateStyle: 'short',
              timeStyle: 'short',
            })}
          </span>
        </p>
        <p className='product-subheads product-brand mb-2'>
          <span className={styles.label}>UpdatedAt:</span>
          <span className={styles.value}>
            {new Date(user.updatedAt).toLocaleString('en-UK', {
              dateStyle: 'short',
              timeStyle: 'short',
            })}
          </span>
        </p>

        {/* ------------Commands ---------- */}
        <div className=''>
          <button
            className={`${styles.edit} ${styles.btn}`}
            onClick={updateType}
          >
            {`UPDATE LEVEL  `}
            <FontAwesomeIcon icon='pen-square' />
          </button>
          <label htmlFor='processId' className='form-label mx-1'>
            LEVEL
          </label>
          <select
            defaultValue={type}
            onChange={(evt) => setType(evt.target.value)}
            id='processId'
            name='processId'
            className={`form-select form-control d-inline my-1 ${styles.pid}`}
          >
            <option className='default-select' value='1'>
              1
            </option>
            <option className='default-select' value='2'>
              2
            </option>
            <option className='default-select' value='3'>
              3
            </option>
            <option className='default-select' value='4'>
              4
            </option>
          </select>
          <div className='mb-1'>
            <button
              className={`${styles.list} ${styles.btn}`}
              onClick={() =>
                navigate(`/listings/user/${user.id}`, {
                  state: { userName: `${user.prenom} ${user.nom}` },
                })
              }
            >
              {`USER PRODUCTS  `}
              <FontAwesomeIcon icon='bullhorn' />
            </button>
          </div>
          <div className='mb-1'>
            {user.entrepriseId ? (
              <button
                className={`${styles.attach} ${styles.btn}`}
                onClick={handleUnlink}
              >
                {`UNLINK COMPANY  `}
                <FontAwesomeIcon icon='link' />
              </button>
            ) : (
              <button
                className={`${styles.attach} ${styles.btn}`}
                onClick={() =>
                  navigate(`/user/link/${user.id}`, {
                    state: { userName: `${user.prenom} ${user.nom}` },
                  })
                }
              >
                {`LINK COMPANY  `}
                <FontAwesomeIcon icon='link' />
              </button>
            )}
          </div>

          {confirmDelete ? (
            <div>
              <button
                className={`${styles.del} ${styles.btn}`}
                onClick={deleteUser}
              >
                {`DELETE ANYWAY??  `}
                <FontAwesomeIcon icon='trash' />
              </button>
            </div>
          ) : (
            <div>
              <button
                className={`${styles.del} ${styles.btn}`}
                onClick={toggleDelete}
              >
                {`DELETE USER  `}
                <FontAwesomeIcon icon='trash' />
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserCard;
