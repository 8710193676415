import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FooterProfile from '../components/footer_profile';
import Banner from '../components/banner';
import PanelCard from '../components/panelcard';
import { Helmet } from 'react-helmet-async';
import Navbar from '../components/navbar';
import isLoggedIn from '../utils/isLoggedIn';

const Store = () => {
  //set initial states, comics, characters and query filters
  const navigate = useNavigate();
  const dispatch = useDispatch()

  // Panel Icons to be produced ---------------------------------------------------\\

  const icons = [
    {
      link: '/store/orders',
      img: '/img/icons/store.jpg',
      title: 'ORDERS',
      icon: 'shopping-cart',
      tag: '',
      desc: 'view, manage received orders.',
    },
    {
      link: '/store/carts',
      img: '/img/icons/store.jpg',
      title: 'CARTS',
      icon: 'shopping-basket',
      tag: '',
      desc: 'view and track abandoned carts by users.',
    },
    {
      link: '/store',
      img: '/img/icons/store.jpg',
      title: 'LOGISTICS',
      icon: 'truck-moving',
      tag: '',
      desc: 'manger order shipping and fulfullment.',
    },
  ];

  // UseEffects, check if there's a user and token ---------------------------------------------------\\
      useEffect(() => {
        isLoggedIn(navigate, dispatch, '/store');
      }, []);

  // Component return .. ..
  return (
    //Main admin panel .. map and render panel icons
    <>
      <Helmet>
        <title>Badgers SAS: Admin Console</title>
        <meta name='page' content='Admin Console' />
      </Helmet>
      <Navbar displayMob={true} />
      <div className=''>
        <Banner />
        <div className='container main-pan'>
          {icons.length > 0 &&
            icons.map((icon, idx) => {
              return (
                <PanelCard
                  link={icon.link}
                  img={icon.img}
                  title={icon.title}
                  icon={icon.icon}
                  tag={icon.tag}
                  desc={icon.desc}
                  key={idx}
                />
              );
            })}
        </div>
        <FooterProfile />
      </div>
    </>
  );
};

export default Store;
