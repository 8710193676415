import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logoutSuccess } from '../redux/user';

const Navbar = ({ displayMob, setDisplayMob }) => {
  // Initial state and variables ---------------------------------------------------\\

  const dispatch = useDispatch();
  // const token = sessionStorage.getItem('token');
  const { user, token } = useSelector((state) => state.user);
  const navigate = useNavigate();

  //check if user is logged-in
  const handleFavourites = (evt) => {
    navigate('/', { state: { field: evt.target.value } });
  };

  // Logout handler ---------------------------------------------------\\

  const onLogOut = () => {
    dispatch(logoutSuccess());
    navigate('/login');
  };

  return (
    <div className='navbar'>
      <div className='nav'>
        <div className=''>
          <Link to='/'>
            <img
              className='logo'
              src='/img/BDGRS_baseline_full_GRIS.png'
              alt='logo'
            />
          </Link>
          <FontAwesomeIcon
            onClick={() => setDisplayMob(!displayMob)}
            className='burger'
            icon='bars'
          />
        </div>

        <ul className='nav-items'>
          <li>
            <Link to='/'>HOME</Link>
          </li>
          <li>
            <Link to='/listings'> LISTINGS</Link>
          </li>
          <li>
            <Link to='/store'> STORE</Link>
          </li>
          <li>
            <Link to='/quoterequests'> QUOTES</Link>
          </li>
          <li>
            <Link to='/listing/add'> ADD LISTING</Link>
          </li>
          <li>
            {token && (
              <Link id='link' to={'/favourites'}>
                SAVED LISTINGS
              </Link>
            )}
          </li>
        </ul>

        <div className='sign-in' id={displayMob ? '' : 'login-hide'}>
          {!token ? (
            <div className='login-ins' id={token ? 'signin-hide' : 'signin'}>
              <Link to='/login'>
                <span>SIGN IN </span>
                <FontAwesomeIcon className='signin-icon' icon='sign-in-alt' />
              </Link>
            </div>
          ) : (
            <div className='nav-item dropdown login-ins'>
              <a
                className='nav-link dropdown-toggle'
                href='#'
                role='button'
                data-bs-toggle='dropdown'
                aria-expanded='false'
              >
                <FontAwesomeIcon className='username' icon='smile-wink' />
                {` Hello ${user?.userName?.toUpperCase()}`}
              </a>
              <ul className='dropdown-menu'>
                <li>
                  <a className='dropdown-item' href='/admin/update'>
                    Profile
                  </a>
                </li>
                <li>
                  <a className='dropdown-item' href='/admin/settings'>
                    Settings
                  </a>
                </li>
                <li>
                  <a className='dropdown-item' href='/mylistings'>
                    My Listings
                  </a>
                </li>
                <li>
                  <a className='dropdown-item' href='/favourites'>
                    Saved Listings
                  </a>
                </li>
                <li>
                  <hr className='dropdown-divider' />
                </li>
                <li>
                  <a
                    className='dropdown-item'
                    href='/'
                    onClick={() => onLogOut()}
                  >
                    Logout
                    <FontAwesomeIcon
                      className='logout-icon'
                      icon='sign-out-alt'
                    />
                  </a>
                </li>
              </ul>
            </div>
          )}
          <div className='fav-mobile'>
            {token && (
              <Link id='fav-mobile' to={'/favourites'}>
                <FontAwesomeIcon icon='star' />
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
