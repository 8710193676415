import { createSlice } from '@reduxjs/toolkit'
const initialStatus={
    access:true
}
const slice = createSlice({
    name: 'access',
    initialState: {
      access: initialStatus,
    },
    reducers: {

      tokenStatus: (state, action) => {
        state.access = action.payload;
      }
    },
  });
  export default slice.reducer
  // Actions
  export const { tokenStatus} = slice.actions
