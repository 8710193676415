import { createSlice } from '@reduxjs/toolkit';
// Slice

const favsSlice = createSlice({
  name: 'favs',
  initialState: [],
  reducers: {
    setFav: (state, action) => {
      state.push(...action.payload)
    },
    addFav: (state, action) => {
      state.push(action.payload);
    },

    removeFav: (state, action) => {
      const idx = state.indexOf(action.payload);
      state.splice(idx, 1);
    },
  },
});
export default favsSlice.reducer;
// Actions
export const { setFav, addFav, removeFav } = favsSlice.actions;
