import React from 'react';
import { useSelector } from 'react-redux';
import styles from './../../pages/listings.module.css';
import useAnalytics from '../../redux/hooks/useAnalytics';
import ChartProductEngagement from './chart_product_engagement';
import analyticsstyles from './analytics.module.css';
import convertEntities from './../../utils/convertEntities'

const PageHits = ({history}) => {
   const { token } = useSelector((state) => state.user);
  const [pageHits, loadingPageHits] = useAnalytics(
    token,
    'pageTitle',
    'screenPageViews',
    `${history}daysAgo`
  );

  //map data and relative keys
  const dataMapper = (input) =>
    input.reduce((acc, curr) => {
        if (curr.dimensionValues[0].value.includes('Product,')) {
          acc.push({
            product: convertEntities(
              curr.dimensionValues[0].value.split('Product,')[1]
            ),
            engagements: parseInt(curr.metricValues[0].value),
          });
        } 
        return acc;
    }, []);

  return loadingPageHits ? (
    <div className='loading'>
      <img src='/img/loading.gif' alt='Loading' className='loading-img' />
    </div>
  ) : (
    <div className={analyticsstyles.countryvisits}>
      <div>
        <table className='table table-striped table-hover'>
          <thead>
            <tr className={`${styles.headings}`}>
              <th scope='col'>#</th>
              <th scope='col'>Product</th>
              <th scope='col'>Visits</th>
            </tr>
          </thead>
          <tbody>
            {dataMapper(pageHits?.rows).length > 0 ? (
              dataMapper(pageHits?.rows).map((row, idx) => {
                return (
                  <tr key={idx} className={`${styles.rows}`}>
                    <th scope='row'>{idx + 1}</th>
                    <td>{convertEntities(row.product)}</td>
                    <td>{row.engagements}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={11}>No data to display ...</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className={analyticsstyles.rcchart1}>
        <ChartProductEngagement
          data={dataMapper(pageHits?.rows)}
          caption={'Product Engagement Metrics'}
        />
      </div>
    </div>
  );
};

export default PageHits;
