import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { EditListingForm } from '../components/edit_listing_form';
import Banner from '../components/banner';
import isLoggedIn from '../utils/isLoggedIn';
import axios from 'axios';
import Navbar from '../components/navbar';

const EditListing = () => {
  //set initial states, comics, characters and query filters
  const { id } = useParams();
  const url = `${process.env.REACT_APP_API}/admin/product/${id}`;

  const [product, setProduct] = useState(); //Product details state
  const [isloading, setLoading] = useState(true); //Loading isloading toggle
  const { user } = useSelector((state) => state.user);
  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();
  const dispatch = useDispatch()

  // UseEffects, check if there's a user and token ---------------------------------------------------\\

  useEffect(() => {
    // check if user is logged in
    isLoggedIn(navigate, dispatch, `/listing/edit/${id}`);

    let active = true;
    (async () => {
      const productData = await axios(url, {
        headers: { authorization: `Bearer ${token}` },
      });
      if (active) {
        setProduct(productData.data);
        setLoading(false);
      }
    })();
    return () => {
      active = false;
      setProduct(null);
    };
  }, [id]);

  // Login form submit handler ---------------------------------------------------\\

  return isloading ? (
    // Waiting data loading after async request...
    <>
      <Helmet>
        <title>Badgers SAS: Edit product listing</title>
        <meta name='page' content='edit your product listing' />
      </Helmet>
      <div className='loading'>
        <img src='/img/loading.gif' alt='Loading' className='loading-img' />
      </div>
    </>
  ) : (
    <>
      <Helmet>
        <title>Badgers SAS: Edit product listing</title>
        <meta name='page' content='edit your product listing' />
      </Helmet>
      <Navbar displayMob={true} />
      <Banner />
      <EditListingForm user={user} token={token} product={product} />
    </>
  );
};

export default EditListing;
