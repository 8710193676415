import React from 'react';
import { useSelector } from 'react-redux';
import styles from './../../pages/listings.module.css';
import useAnalytics from '../../redux/hooks/useAnalytics';
import ChartCountryVisits from './chart_country_visits';
import analyticsstyles from './analytics.module.css';

const ViewsByLocation = ({history, location, caption}) => {
  const { token } = useSelector((state) => state.user);

  const [viewsByCountry, loadingCountry] = useAnalytics(
    token,
    location,
    'screenPageViews',
    `${history}daysAgo`
  );

  //map data and relative keys
  const dataMapper  = (input) =>  input.map((row) => {
    return {
      name: row.dimensionValues[0].value,
      visits: parseInt(row.metricValues[0].value),
    }});
  

  return loadingCountry ? (
    <div className='loading'>
      <img src='/img/loading.gif' alt='Loading' className='loading-img' />
    </div>
  ) : (
    <div className={analyticsstyles.countryvisits}>
      <div>
        <table className='table table-striped table-hover'>
          <thead>
            <tr className={`${styles.headings}`}>
              <th scope='col'>#</th>
              <th scope='col'>Country</th>
              <th scope='col'>Visits</th>
            </tr>
          </thead>
          <tbody>
            {viewsByCountry?.rows.length > 0 ? (
              viewsByCountry?.rows.map((row, idx) => {
                return (
                  <tr key={idx} className={`${styles.rows}`}>
                    <th scope='row'>{idx + 1}</th>
                    <td>{row.dimensionValues[0].value}</td>
                    <td>{row.metricValues[0].value}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={11}>No data to display ...</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* -------------------Graph------------------*/}
      <div className={analyticsstyles.rcchart1}>
        <ChartCountryVisits data={dataMapper(viewsByCountry?.rows)} caption={caption} />
      </div>
    </div>
  );
};

export default ViewsByLocation;
