import React from 'react'
import { useNavigate } from 'react-router-dom';
import styles from './../pages/listings.module.css'

const ProductsTable = ({products}) => {
    const navigate = useNavigate()

  return (
    <>
      <table className='table table-striped table-hover'>
        <thead>
          <tr className={`${styles.headings}`}>
            <th scope='col'>#</th>
            <th scope='col'>Category</th>
            <th scope='col'>Type</th>
            <th scope='col'>Brand</th>
            <th scope='col'>Price</th>
            <th scope='col'>Condition</th>
            <th scope='col'>Owner</th>
            <th scope='col'>Country</th>
            <th scope='col'>Region</th>
            <th scope='col'>Updated at</th>
          </tr>
        </thead>
        <tbody>
          {products.length > 0 ? (
            products.map((product, idx) => {
              return (
                <tr
                  key={product.id}
                  className={`${styles.rows} ${
                    !product.validated && 'table-danger'
                  }`}
                  onClick={() => navigate(`/product/${product.id}`)}
                >
                  <th scope='row'>{idx + 1}</th>
                  <td>{product.categorie}</td>
                  <td>{product.type}</td>
                  <td>{product.brand}</td>
                  <td>
                    {product.currency === 'usd' ? '$ ' : '€ '}
                    {Number(product.price).toLocaleString('en')}
                  </td>
                  <td>{product.condition}</td>
                  <td>{product.legalOwner}</td>
                  <td>{product.country}</td>
                  <td>{product.region}</td>
                  <td>
                    {new Date(product.updatedAt).toLocaleString('en-UK', {
                      dateStyle: 'short',
                      timeStyle: 'short',
                    })}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={11}>No Products to display ...</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}

export default ProductsTable