import styles from './../pages/product.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { useState, useRef } from 'react';
import toast from 'react-hot-toast';
import axios from 'axios';
import React from 'react';

const BuyerDetails = ({ user, delivery }) => {

  const { token } = useSelector((state) => state.user);
  const [tracking, setTracking] = useState(delivery.trackingId);
  const [displayUpdateInput, setDisplayUpdateInput] = useState(false);
  const addTrackingInput = useRef();

  const addTrackingHandler = async () => {
    const trackingId = addTrackingInput.current.value;
    if (trackingId.length < 5 ){
      return toast.error('Tracking id must not shorter than 5 characters.', {
          duration: 3000,
        });
    }
    axios
      .put(
        `${process.env.REACT_APP_API}/admin/shipping/delivery/addtracking/${delivery.id}/${delivery.orderId}`,
        { trackingId },
        {
          headers: { authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        if ((response.status = 200)) {
          toast.success(response?.data?.message, {
            duration: 3000,
          });
          setTracking(trackingId)
          setDisplayUpdateInput(false)
        }
      })
      .catch((err) => {
        toast.error(err.message, {
          duration: 3000,
        });
      });
  };


  return (
    <>
      {/* ------------Product images carrousel end----------- */}
      <div className='product-block col-md-6'>
        <h4 className='h5'>{`Buyer: ${user.prenom?.toUpperCase()} ${user.nom?.toUpperCase()}`}</h4>
        <h5 className='h6'>{`${user.ville}, ${user.pays}`}</h5>
        <p className='product-subheads product-type mb-2 mt-3'>
          <span className={styles.label}>Email:</span>
          <span className={styles.value}>{user.email}</span>
        </p>
        <p className='product-subheads product-type mb-2'>
          <span className={styles.label}>Tel:</span>
          <span className={styles.value}>{user.contact}</span>
        </p>
        <p className='product-subheads product-brand mb-2'>
          <span className={styles.label}>Company:</span>
          <span className={styles.value}>{user.entreprise}</span>
        </p>

        <h5 className='h5 mt-4'>Shipping Address:</h5>

        {!tracking ? (
          <p className='product-subheads product-brand mb-2'>
            <span className={styles.label}>Tracking ID:</span>
            <span>
              <input
                ref={addTrackingInput}
                maxLength={25}
                minLength={5}
                type='text'
                id='tracking'
                name='tracking'
                placeholder='Type shipment tracking'
                className='form-control-sm'
              />

              <button
                className={`${styles.edit} ${styles.btn}`}
                onClick={addTrackingHandler}
              >
                {`ADD TRACKING  `}
                <FontAwesomeIcon icon='plus' />
              </button>
            </span>
          </p>
        ) : (
          <p className='product-subheads product-brand mb-2'>
            <span className={styles.label}>Tracking ID:</span>
            {displayUpdateInput ? (
              <>
                <span>
                  <input
                    defaultValue={tracking}
                    ref={addTrackingInput}
                    maxLength={25}
                    minLength={5}
                    type='text'
                    id='tracking'
                    name='tracking'
                    placeholder='Type shipment tracking'
                    className='form-control-sm'
                  />

                  <button
                    className={`${styles.edit} ${styles.btn}`}
                    onClick={addTrackingHandler}
                  >
                    {`UPDATE TRACKING  `}
                    <FontAwesomeIcon icon='pen-square' />
                  </button>
                </span>
              </>
            ) : (
              <>
                <span className={styles.value}>
                  {' '}
                  <a
                    href={`https://www.dhl.com/fr-en/home/tracking.html?tracking-id=${tracking}&submit=1`}
                    target='_blank'
                  >{tracking}</a>
                </span>
                <button
                  className={`${styles.edit} ${styles.btnsm}`}
                  onClick={() => {
                    setDisplayUpdateInput(true);
                  }}
                >
                  <FontAwesomeIcon icon='pen-square' />
                </button>
              </>
            )}
          </p>
        )}
        <p className='product-subheads product-type mb-2'>
          <span className={styles.label}>Shipped by:</span>
          <span
            className={styles.value}
          >{`${delivery.shippedBy}, Product Code: ${delivery.productCode}`}</span>
        </p>
        {delivery.address ? (
          <p className='product-subheads product-brand mb-2'>
            <span className={styles.label}>Street Address:</span>
            <span className={styles.value}>{delivery.address}</span>
          </p>
        ) : undefined}
        <p className='product-subheads product-brand mb-2'>
          <span className={styles.label}>City:</span>
          <span className={styles.value}>{delivery.city}</span>
        </p>
        <p className='product-subheads product-brand mb-2'>
          <span className={styles.label}>Postcode:</span>
          <span className={styles.value}>{delivery.postcode}</span>
        </p>
        <p className='product-subheads product-brand mb-2'>
          <span className={styles.label}>Country:</span>
          <span className={styles.value}>{delivery.country}</span>
        </p>
      </div>
    </>
  );
};

export default BuyerDetails;
