import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';



const ChartUsersOverTime = ({ series, caption }) => {
  const colors = [
    '#3366E6',
    '#999966',
    '#99FF99',
    '#B34D4D',
    '#80B300',
    '#809900',
    '#E6B3B3',
    '#6680B3',
    '#66991A',
    '#FF99E6',
    '#CCFF1A',
    '#FF1A66',
    '#E6331A',
    '#33FFCC',
    '#66994D',
    '#B366CC',
    '#4D8000',
    '#B33300',
    '#CC80CC',
    '#66664D',
    '#991AFF',
    '#E666FF',
    '#4DB3FF',
    '#1AB399',
    '#E666B3',
    '#33991A',
    '#CC9999',
    '#B3B31A',
    '#00E680',
    '#4D8066',
    '#809980',
    '#E6FF80',
    '#1AFF33',
    '#999933',
    '#FF3380',
    '#CCCC00',
    '#66E64D',
    '#4D80CC',
    '#9900B3',
    '#E64D66',
    '#4DB380',
    '#FF4D4D',
    '#99E6E6',
    '#6666FF',
  ];

  const formaTick = (tick) =>
    new Date(tick)?.toLocaleDateString('fr-FR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    
 
  return (
    <ResponsiveContainer width='100%' aspect={2}>
      <LineChart>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis
          dataKey='date'
          type='date'
          tickFormatter={formaTick}
          padding={{ left: 30, right: 30 }}
        />
        <YAxis
          orientation='right'
          dataKey='visits'
          margin={{
            top: 5,
            left: 30,
          }}
        />
        <Tooltip />
        <Legend payload={[{ value: caption }]} verticalAlign='bottom' />
        {series.map((s, idx) => (
          <Line
            connectNulls={true}
            strokeWidth={2}
            dataKey='visits'
            data={s.data}
            name={s.name}
            key={s.name}
            stroke={colors[idx]}
            activeDot={{ r: 8 }}
            type='monotone'
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ChartUsersOverTime;
