import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useNavigate} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { truncate } from '../utils/utils';
import { addFav, removeFav } from '../redux/favs';

const Product = ({ product, token }) => {

  const url = `${process.env.REACT_APP_API}/api`;
  const favs = useSelector((state) => state.favs);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Add to Favourite.. helper function
  const addFavHandler = async (evt) => {
    evt.stopPropagation();
    if (!token) {
      console.log(
        'No logged user found..Please log in before adding favourites'
      );
    } else {
      
      dispatch(addFav(product.id))
      const reqBody = { productId: product.id };
      axios.post(
        `${url}/user/addfav`,reqBody,
        {headers: { authorization: `Bearer ${token}`}}
      ).then( response => {
        if (response.status === 200) {
        } else {
          dispatch(removeFav(product.id));
        }

      }).catch( err => {
        console.log(err.message);
        dispatch(removeFav(product.id))
      })
    }
  };

  //Remov from Favourite.. helper function
  const removeFavHandler = async (evt) => {
    evt.stopPropagation();
    if (!token) {
      console.log(
        'No logged user found..Please log in before adding favourites'
      );
    } else {
      dispatch(removeFav(product.id));
      axios
        .delete(`${url}/user/delfav/${product.id}`, {
          headers: { authorization: `Bearer ${token}` },
        })
        .then((response) => {
          if (response.status === 201) {
          } else {
             dispatch(addFav(product.id));
          }
        })
        .catch((err) => {
          console.log(err.message);
          dispatch(addFav(product.id));
        });
    }
  };

  return (
    <div
      onClick={() => navigate(`/product/${product.id}`)}
      className='product-card'
      key={product?.id.toString()}
    >
      <div className='product-img'>
        <img className='product-img' src={product.images[0]?.lienImage} alt='' />

        {token &&
          (favs.includes(product.id) ? (
            <div>
              <button
                className='favicon'
                onClick={(evt) => removeFavHandler(evt)}
                aria-label='Remove from Favourites'
              >
                <FontAwesomeIcon  name='remove from favourites' icon='star' />
              </button>
            </div>
          ) : (
            <div>
              <button
                className='favicon'
                onClick={(evt) => addFavHandler(evt)}
                aria-label='Add to Favourites'
              >
                <FontAwesomeIcon name='add to favourites' icon='plus' />
              </button>
            </div>
          ))}
      </div>

      <div className='product-details'>
        <div>
          <p className='product-title'>{product.title}</p>
        </div>
        <div className='product-labels'>
          <p className='product-price'>
            {product.currency === 'usd' ? '$ ' : '€ '}
            {Number(product.price).toLocaleString('en')}
          </p>
          <p className='product-subheads product-brand'>{product.brand}</p>
          <p className='product-subheads product-type'>{product.type}</p>
          <p className='product-subheads product-condition'>
            {product.condition}
          </p>
          <p className='product-subheads product-location'>{product.region}</p>
          {/* <p className='product-desc'>{truncate(product.description, 200)}</p> */}
        </div>
        <div>
          <p className='product-description'>
            {truncate(product.description, 48)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Product;
