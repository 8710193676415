import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import styles from './info.module.css';
import Navbar from '../components/navbar';

const Values = () => {
  // Initial state and variables ---------------------------------------------------\\

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Badgers SAS: Our values</title>
        <meta name='page' content='our values' />
      </Helmet>
      <Navbar displayMob={true} />
      <div className={styles.pan}>
        <div className='py-2'>
          <Link className='page-links' to='/'>
            <FontAwesomeIcon icon='home' />{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <span className='link-span'>Our Values</span>
        </div>
        <div className='col-12 d-flex justify-content-center my-4'>
          <h4 className='h4'>
            About Us <FontAwesomeIcon icon='people-arrows' />
          </h4>
        </div>
        <div className='row g-3' action=''>
          <p>
            We are a start-up business that helps oil and mining companies
            improve their surplus materials management with digital
            technologies. We have more than 40 years cumulative experience and
            throughout our careers, we have witnessed projects delayed because
            materials were not available while other companies were sending the
            same materials for scrapping. Our mission is to inspire action for a
            better future.
          </p>
          <h5 className='h5'>VISION</h5>
          <p>
            To provide value to our customers, employees, and shareholders till
            the next century.
          </p>
          <p>By 2030:</p>
          <ul>
            <li>We aim to serve 3,000 users.</li>
            <li>Create 300 job.</li>
            <li>Generate a 30-fold return for our investors.</li>
          </ul>
          <h5 className='h5'>VALUES</h5>
          <ul className='list-unstyled'>
            <li>
              <strong>Agility</strong> - We move fast to adapt to customer needs
              and market conditions.
            </li>
            <li>Care - We care about humanity and the environment.</li>
            <li>
              Connection - We foster collaboration, cooperation and business
              connections.
            </li>
            <li>Ethic - We promote inclusion and fair business dealings.</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Values;
