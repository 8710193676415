import React from 'react'
import styles from '../../pages/listings.module.css';
import useUsersByCountry from '../../redux/hooks/useUsersByCountry';
import analyticsstyles from './analytics.module.css';


const UserEngagement = ({history}) => {
  //initial state and hooks initialization...

  const token = sessionStorage.getItem('token');
  const [usersByCountry, loadingUserCountry] = useUsersByCountry(
    token,
    'country',
    `${history}daysAgo`
  );

  //conver seconds to minuts
  function convertToMinutes(seconds) {
    let minutes = ~~(seconds / 60);
    let extraSeconds = seconds % 60;

    return `${minutes}m ${extraSeconds}s`;
  }

  return (loadingUserCountry ? (
            <div className='loading'>
              <img
                src='/img/loading.gif'
                alt='Loading'
                className='loading-img'
              />
            </div>
          ) : (
            <div className={analyticsstyles.engagements}>
              <table className='table table-striped table-hover'>
                <thead>
                  <tr className={`${styles.headings}`}>
                    <th scope='col'>#</th>
                    <th scope='col'>Country</th>
                    <th scope='col'>Total Users</th>
                    <th scope='col'>New Users</th>
                    <th scope='col'>Engaged Sessions</th>
                    <th scope='col'>Engagement Rate</th>
                    <th scope='col'>Sessions per User</th>
                    <th scope='col'>Engagement Duration</th>
                    <th scope='col'>Average Session Duration</th>
                    <th scope='col'>Event Count</th>
                  </tr>
                </thead>
                <tbody>
                  {usersByCountry?.rows.length > 0 ? (
                    usersByCountry?.rows.map((row, idx) => {
                      return (
                        <tr key={idx} className={`${styles.rows}`}>
                          <th scope='row'>{idx + 1}</th>
                          <td>{row.dimensionValues[0].value}</td>
                          <td>{row.metricValues[0].value}</td>
                          <td>{row.metricValues[1].value}</td>
                          <td>{row.metricValues[2].value}</td>
                          <td>
                            {(row.metricValues[3].value * 100).toFixed(2)} %
                          </td>
                          <td>{Number(row.metricValues[4].value).toFixed(2)}</td>
                          <td>{convertToMinutes(row.metricValues[5].value)}</td>
                          <td>
                            {convertToMinutes(~~row.metricValues[6].value)}
                          </td>
                          <td>{row.metricValues[7].value}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={11}>No quote requests to display ...</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          ))
}

export default UserEngagement;