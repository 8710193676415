import styles from './../pages/product.module.css';
import React from 'react';

const ProductDetails = ({ product }) => {
  return (
    <>
      <div className={`${styles.card} row g-4 my-3 pb-3`}>
        {/* -------------Product images carrousel start---------- */}
        <div
          id='carouselExampleIndicators'
          className='col-md-6 carousel slide'
          data-bs-ride='true'
        >
          <div className='carousel-indicators'>
            {product.images.length > 0 &&
              product.images.map((photo, i) => {
                return (
                  <button
                    key={i}
                    type='button'
                    data-bs-target='#carouselExampleIndicators'
                    data-bs-slide-to={i.toString()}
                    className={i === 0 && 'active'}
                    aria-current={i === 0 && 'true'}
                    aria-label={`Slide ${i + 1}`}
                  ></button>
                );
              })}
          </div>
          <div className='carousel-inner'>
            {product.images.length > 0 &&
              product.images.map((photo, i) => {
                return (
                  <div
                    className={`carousel-item ${i === 0 && 'active'}`}
                    key={i}
                  >
                    <img
                      src={photo.lienImage}
                      className='d-block w-100'
                      alt={`product-image-${i}`}
                    />
                  </div>
                );
              })}
          </div>
          <button
            className='carousel-control-prev'
            type='button'
            data-bs-target='#carouselExampleIndicators'
            data-bs-slide='prev'
          >
            <span
              className='carousel-control-prev-icon'
              aria-hidden='true'
            ></span>
            <span className='visually-hidden'>Previous</span>
          </button>
          <button
            className='carousel-control-next'
            type='button'
            data-bs-target='#carouselExampleIndicators'
            data-bs-slide='next'
          >
            <span
              className='carousel-control-next-icon'
              aria-hidden='true'
            ></span>
            <span className='visually-hidden'>Next</span>
          </button>
        </div>

        {/* ------------Product images carrousel end----------- */}
        <div className='product-block col-md-6'>
          <h4 className='h4'>{product.title}</h4>
          <h5 className='h5'>
            {product.currency === 'usd' ? '$ ' : '€ '}
            {Number(product.price).toLocaleString('en')}
          </h5>
          <p className='product-subheads product-type mb-2 mt-3'>
            <span className={styles.label}>Category:</span>
            <span className={styles.value}>{product.categorie}</span>
          </p>
          <p className='product-subheads product-type mb-2'>
            <span className={styles.label}>Type:</span>
            <span className={styles.value}>{product.type}</span>
          </p>
          <p className='product-subheads product-brand mb-2'>
            <span className={styles.label}>Brand, Manufacturer:</span>
            <span className={styles.value}>{product.brand}</span>
          </p>
          <p className='product-subheads product-condition mb-2'>
            <span className={styles.label}>Condition:</span>
            <span className={styles.value}>{product.condition}</span>
          </p>
          <p className='product-subheads product-condition mb-2'>
            <span className={styles.label}>Legal Owner:</span>
            <span className={styles.value}>{product.legalOwner}</span>
          </p>
          <p className='product-subheads product-location mb-2'>
            <span className={styles.label}>City:</span>
            <span className={styles.value}>{product.city}</span>
          </p>
          <p className='product-subheads product-location mb-2'>
            <span className={styles.label}>Country:</span>
            <span className={styles.value}>{product.country}</span>
          </p>
          {/* <p className='product-subheads product-location mb-2'>
            <span className={styles.label}>Region:</span>
            <span className={styles.value}>{product?.region}</span>
          </p> */}
          <p className='product-subheads product-location mb-4'>
            <span className={styles.label}>Available Units:</span>
            <span className={styles.value}>{product?.stocks[0].qty}</span>
          </p>
          <p className='product-subheads product-location mb-2'>
            <span className={styles.label}>Updated at:</span>
            <span className={styles.value}>
              {new Date(product.updatedAt).toLocaleString('en-UK', {
                dateStyle: 'long',
                timeStyle: 'short',
              })}
            </span>
          </p>
        </div>
      </div>
      <div className={`${styles.card} row my-3 py-3`}>
        <h5 className='h5 mb-2'>Description:</h5>
        <p
          key={product.id}
          dangerouslySetInnerHTML={{ __html: product.description }}
        />
      </div>
    </>
  );
};

export default ProductDetails;
