import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import QuoteStage from '../data/processId.json';
import axios from 'axios';
import QuoteFilter from '../components/quote_filter';
import FooterProfile from '../components/footer_profile';
import styles from './listings.module.css';
import { truncate } from '../utils/utils';
import Navbar from '../components/navbar';
import isLoggedIn from '../utils/isLoggedIn';
import convertEntities from '../utils/convertEntities';

const QuoteRequests = () => {
  //set initial states, comics, characters and query filters
  const url = `${process.env.REACT_APP_API}`;
  const token = sessionStorage.getItem('token');
  const [isLoading, setLoading] = useState(true);
  const [count, setCount] = useState();
  const [requests, setRequests] = useState([]);
  const [filters, setFilters] = useState({
    country: '',
    searchStr: '',
    processId: '',
    displayCount: 20,
    page: 1,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch()

  //Get all products and equipments to display -------------\\
  useEffect(() => {
    const getRequests = async () => {
      const { country, searchStr, processId, displayCount, page } = filters;
      // Get listings on page load..
      const response = await axios.get(
        `${url}/admin/quoterequests?country=${country}&searchStr=${searchStr}&processId=${processId}&displayCount=${displayCount}&page=${page}`,
        { headers: { authorization: `Bearer ${token}` } }
      );
      const [count, requests] = response.data;
      setRequests(requests);
      setCount(count);

      setLoading(false);
    };

    //check if user is logged in
    isLoggedIn(navigate, dispatch, '/quoterequests');

    const timeoutId = setTimeout(() => {
      getRequests();
    }, 300);

    return () => {
      clearTimeout(timeoutId);
      setLoading(false);
    };
  }, [filters]);

  // Component return .. .. COMIC or CHARACTER;;
  return isLoading ? (
    // Waiting data loading after async request...
    <>
      <Helmet>
        <title>Badgers SAS: All price quote requests</title>
        <meta name='page' content='all price quote requests' />
      </Helmet>
      <div className='loading'>
        <img src='/img/loading.gif' alt='Loading' className='loading-img' />
      </div>
    </>
  ) : (
    //All products .. map and render products table
    <>
      <Helmet>
        <title>Badgers SAS: All price quote requests</title>
        <meta name='page' content='all price quote requests' />
      </Helmet>
      <Navbar displayMob={true} />
      <div className=''>
        <QuoteFilter
          count={count}
          filters={filters}
          setFilters={setFilters}
          displayMob={true}
        />
        <div className='container'>
          <div>
            <Link className='page-links' to='/'>
              <FontAwesomeIcon icon='home' />{' '}
            </Link>
            <span className='link-span'>
              <FontAwesomeIcon icon='angle-right' />
            </span>
            <span className='link-span'>PRICE QUOTE REQUESTS</span>
          </div>

          <div className='container'>
            <div className='col-12 d-flex justify-content-center my-4'>
              <h4 className='h4'>
                PRICE QUOTE REQUESTS <FontAwesomeIcon icon='bullhorn' />
              </h4>
            </div>

            <table className='table table-striped table-hover'>
              <thead>
                <tr className={`${styles.headings}`}>
                  <th scope='col'>#</th>
                  <th scope='col'>Product ID</th>
                  <th scope='col'>Product</th>
                  <th scope='col'>Shipping Country</th>
                  <th scope='col'>Quantity</th>
                  <th scope='col'>Requested by</th>
                  <th scope='col'>Message</th>
                  <th scope='col'>Process Id</th>
                  <th scope='col'>Requested at</th>
                </tr>
              </thead>
              <tbody>
                {requests.length > 0 ? (
                  requests.map((req, idx) => {
                    return (
                      <tr
                        key={req.id}
                        className={`${styles.rows} ${
                          req.processId === 1
                            ? 'table-danger'
                            : req.processId === 6
                            ? 'table-success'
                            : null
                        }`}
                        onClick={() => navigate(`/quoterequest/${req.id}`)}
                      >
                        <th scope='row'>{idx + 1}</th>
                        <td>{req.materielId}</td>
                        <td>{req.Materiel.title}</td>
                        <td>{req.country}</td>
                        <td>{req.qty}</td>
                        <td>{`${req.User.nom}, ${req.User.pays}`}</td>
                        <td>{convertEntities(truncate(req.message, 50))}</td>
                        <td>{QuoteStage[req.processId]}</td>
                        <td>
                          {new Date(req.createdAt).toLocaleString('en-UK', {
                            dateStyle: 'short',
                            timeStyle: 'short',
                          })}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={11}>No quote requests to display ...</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <FooterProfile />
      </div>
    </>
  );
};

export default QuoteRequests;
