import React from 'react';
import { useSelector } from 'react-redux';
import styles from './../../pages/listings.module.css';
import useAnalytics from '../../redux/hooks/useAnalytics';
import ChartCountryVisits from './chart_country_visits';
import ChartSessionsByChannel from './chart_sessions_channel';
import analyticsstyles from './analytics.module.css';

const SessionsByChannel = ({history, token }) => {
  const [sessionsByChannel, loadingSessionsbyChannel] = useAnalytics(
    token,
    'sessionDefaultChannelGroup',
    'sessions',
    `${history}daysAgo`
  );

  //map data and relative keys
  const dataMapper = (input) =>
    input.map((row) => {
      return {
        channel: row.dimensionValues[0].value,
        sessions: parseInt(row.metricValues[0].value),
      };
    });

  return loadingSessionsbyChannel ? (
    <div className='loading'>
      <img src='/img/loading.gif' alt='Loading' className='loading-img' />
    </div>
  ) : (
    <div className={analyticsstyles.countryvisits}>
      <div>
        <table className='table table-striped table-hover'>
          <thead>
            <tr className={`${styles.headings}`}>
              <th scope='col'>#</th>
              <th scope='col'>Channel</th>
              <th scope='col'>Sessions</th>
            </tr>
          </thead>
          <tbody>
            {sessionsByChannel?.rows.length > 0 ? (
              sessionsByChannel?.rows.map((row, idx) => {
                return (
                  <tr key={idx} className={`${styles.rows}`}>
                    <th scope='row'>{idx + 1}</th>
                    <td>{row.dimensionValues[0].value}</td>
                    <td>{row.metricValues[0].value}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={11}>No data to display ...</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className={analyticsstyles.rcchart1}>
        <ChartSessionsByChannel data={dataMapper(sessionsByChannel?.rows)} caption={'Traffic Acquisition Channels'}/>
      </div>
    </div>
  );
};

export default SessionsByChannel;
