import styles from './../pages/product.module.css';
import axios from 'axios';
import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';

const QuoteDetails = ({ quote }) => {
  const [confirmDelete, toggleConfirmDelete] = useState(false); //Loading isloading toggle
  const [pid, setPID ] = useState(quote.processId);
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.user);

  // Delete quote  handler ------------------------------------------------>>

  const deleteQuote = async () => {
   
    axios
      .delete(
        `${process.env.REACT_APP_API}/admin/quoterequest/delete/${quote.id}`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        if ((response.status === 204)) {
          toast.success(
            'Product quote resquest was successfully deleted from the database.',
            {
              duration: 3000,
            }
          );
          setTimeout(() => {
            navigate('/quoterequests');
          }, 2000);
        }
      })
      .catch((err) => {
        toast.error(err.message, {
          duration: 3000,
        });
      });
  };

  const updatePID = async () => {

       axios
         .put(
           `${process.env.REACT_APP_API}/admin/quoterequest/update/${quote.id}`,
           {pid},
           {
             headers: { authorization: `Bearer ${token}` },
           }
         )
         .then((response) => {
           if ((response.status = 200)) {
             toast.success(response?.data?.message, {
               duration: 3000,
             });
           }
         })
         .catch((err) => {
           toast.error(err.message, {
             duration: 3000,
           });
         });




  };

  //Delete confirmation button toggle handler
  const toggleDelete = () => {
    toggleConfirmDelete((state) => !state);
  };

  return (
    <>
      <div className='product-block col-md-6'>
        <h4 className='h4'>{`Required for: ${quote.country}`}</h4>
        <h5 className='h5'>{`Request ID: ${quote.id}`}</h5>

        <div className=''>
          <button
            className={`${styles.edit} ${styles.btn}`}
            onClick={updatePID}
          >
            {`UPDATE PID  `}
            <FontAwesomeIcon icon='pen-square' />
          </button>
          <label htmlFor='processId' className='form-label my-3 mx-1'>
            PID
          </label>
          <select
            defaultValue={quote.processId}
            onChange={(evt) => setPID(evt.target.value)}
            id='processId'
            name='processId'
            className={`form-select form-control d-inline ${styles.pid}`}
          >
            <option className='default-select' value='1'>
              1
            </option>
            <option className='default-select' value='2'>
              2
            </option>
            <option className='default-select' value='3'>
              3
            </option>
            <option className='default-select' value='4'>
              4
            </option>
            <option className='default-select' value='5'>
              5
            </option>
            <option className='default-select' value='6'>
              6
            </option>
          </select>

          {confirmDelete ? (
            <p>
              <button
                className={`${styles.del} ${styles.btn}`}
                onClick={deleteQuote}
              >
                {`DELETE ANYWAY??  `}
                <FontAwesomeIcon icon='trash' />
              </button>
            </p>
          ) : (
            <p>
              <button
                className={`${styles.del} ${styles.btn}`}
                onClick={toggleDelete}
              >
                {`DELETE QUOTE  `}
                <FontAwesomeIcon icon='trash' />
              </button>
            </p>
          )}
        </div>

        <p className='product-subheads product-type mb-2 mt-3'>
          <span className={styles.label}>Required Quantity:</span>
          <span className={styles.value}>{quote.qty}</span>
        </p>
        <p className='product-subheads product-brand mb-2'>
          <span className={styles.label}>Process ID:</span>
          <span className={styles.value}>{quote.processId}</span>
        </p>
        <p className='product-subheads product-type mb-2'>
          <span className={styles.label}>Requested on:</span>
          <span className={styles.value}>
            {new Date(quote.createdAt).toLocaleString('en-UK', {
              dateStyle: 'long',
              timeStyle: 'short',
            })}
          </span>
        </p>
        <p className='product-subheads product-condition mb-2'>
          <span className={styles.label}>Message:</span>
        </p>
        <pre className='product-subheads product-condition mb-2'>{quote.message}</pre>
      </div>
    </>
  );
};

export default QuoteDetails;
