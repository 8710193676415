import { createSlice } from '@reduxjs/toolkit'

const initialEqpt =  {
    nom:'',
    location:'',
    condition:''
}
const slice = createSlice({
    name: 'eqpt',
    initialState: {
        eqpt: initialEqpt,
    },
    reducers: {
        browseByEqpt: (state, action) => {
        state.eqpt = action.payload;
        },
        browseByLocation: (state, action) => {
        state.eqpt = action.payload;
        },
        browseBycondition: (state, action) => {
        state.eqpt = action.payload;
        },
    },
  });
  export default slice.reducer;
  export const { browseByEqpt, browseBycondition, browseByLocation } = slice.actions