import { useParams, Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import toast, { Toaster } from 'react-hot-toast';
import styles from './../pages/product.module.css';
import axios from 'axios';
import OrderDetails from '../components/order_details';
import BuyerDetails from '../components/buyer_details';
import Articles from '../components/articles';
import Navbar from '../components/navbar';
import isLoggedIn from '../utils/isLoggedIn';

const Order = () => {
  const { id } = useParams();
  const url = `${process.env.REACT_APP_API}/admin/order/${id}`;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [order, setOrder] = useState(); //Product details state
  const [user, setUser] = useState(); //Client details state
  const [delivery, setDelivery] = useState(); //Client details state
  const [articles, setArticles] = useState([]); //Product details state
  const [isloading, setLoading] = useState(true); //Loading isloading toggle

  const token = sessionStorage.getItem('token');

  // Query to get order data
  useEffect(() => {
    //check if user is logged in
    isLoggedIn(navigate, dispatch, `/store/order/${id}`);

    let active = true;

    (async () => {
      const response = await axios(url, {
        headers: { authorization: `Bearer ${token}` },
      });
      if (active) {
        const { delivery, User, articles } = response.data;
        setOrder(response.data);
        setUser(User);
        setDelivery(delivery[0]);
        setArticles(articles);
        setLoading(false);
      }
    })();
    return () => {
      active = false;
      setOrder(null);
      setArticles([]);
      setDelivery(null);
      setUser(null);
    };
  }, [id, token]);

  //handle listing valitaion

  // -----------------------------------------------------------------------------

  return isloading ? (
    // Waiting data loading after async request...
    <>
      <Helmet>
        <title>Badgers SAS: Detailed order</title>
        <meta name='page' content='detailed order' />
      </Helmet>
      <div className='loading'>
        <img src='/img/loading.gif' alt='Loading' className='loading-img' />
      </div>
    </>
  ) : (
    <>
      <Helmet>
        <title>Badgers SAS: Detailed order</title>
        <meta name='page' content='detailed order' />
      </Helmet>
      <Navbar displayMob={true} />
      <div className={`${styles.background} container`}>
        <Toaster position='top-center' reverseOrder={false} />
        {/* ------------Navlinks----------- */}
        <div className=''>
          <Link className='page-links' to='/'>
            <FontAwesomeIcon icon='home' />{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <Link className='page-links' to='/store/orders'>
            ORDERS{' '}
          </Link>
          <span className='link-span'>
            <FontAwesomeIcon icon='angle-right' />
          </span>
          <span className='link-span'>{order.id}</span>
        </div>
        {/* ----------------------- */}
        <div className='col-12 d-flex justify-content-center my-4'>
          <h4 className='h4'>
            ORDER DETAILS <FontAwesomeIcon icon='shopping-cart' />
          </h4>
        </div>
        <div className={`${styles.card} row g-4 my-3 pb-3`}>
          <OrderDetails order={order} payment={order.payment[0]} />
          <BuyerDetails user={user} delivery={delivery} />
        </div>
        <Articles articles={articles} currency={order.payment[0].currency} />
      </div>
    </>
  );
};

export default Order;
