import React from 'react';
import {
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList, Label,
  ResponsiveContainer,
} from 'recharts';


const ChartCountryVisits = ({data, caption}) => {

  
  const colors = [
      '#FF33FF',
      '#00B3E6',
      '#E6B333',
      '#3366E6',
      '#999966',
      '#99FF99',
      '#B34D4D',
      '#80B300',
      '#809900',
      '#E6B3B3',
      '#6680B3',
      '#66991A',
      '#FF99E6',
      '#CCFF1A',
      '#FF1A66',
      '#E6331A',
      '#33FFCC',
      '#66994D',
      '#B366CC',
      '#4D8000',
      '#B33300',
      '#CC80CC',
      '#66664D',
      '#991AFF',
      '#E666FF',
      '#4DB3FF',
      '#1AB399',
      '#E666B3',
      '#33991A',
      '#CC9999',
      '#B3B31A',
      '#00E680',
      '#4D8066',
      '#809980',
      '#E6FF80',
      '#1AFF33',
      '#999933',
      '#FF3380',
      '#CCCC00',
      '#66E64D',
      '#4D80CC',
      '#9900B3',
      '#E64D66',
      '#4DB380',
      '#FF4D4D',
      '#99E6E6',
      '#6666FF',
    
  ];

    const getColor = (length, index) => {
      if (length <= colors.length) {
        return colors[index];
      }

      return colors[colors.length - 1];
    };


    return (
      <ResponsiveContainer width='100%' height='98%'>
        <BarChart
          layout='vertical'
          width='100%'
          height='100%'
          data={data}
          margin={{
            top: 5,
            left: 155,
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <YAxis
            type='category'
            tickMargin={10}
            dataKey='name'
            interval={0}
          />
          <XAxis type='number' />
          <Tooltip />
          <Legend
            payload={[{ value: caption }]}
            margin={{
              top: 80,
            }}
            align='center'
            iconType='plainline'
          />
          <Bar
            dataKey='visits'
            minPointSize={2}
            barSize={20}
          >
            {data.map((d, idx) => {
              return <Cell key={d['name']} fill={getColor(data.length, idx)} />;
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
}

export default ChartCountryVisits;


