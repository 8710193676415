import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import cats from '../data/categories.json';
import { useDispatch, useSelector } from 'react-redux';
import { setFilters, setValidation, setDisplayCount, setPage, nextPage, prevPage } from '../redux/filters';
import { truncate } from "../utils/utils";


// Filter Component -------------------------------------------------

const Filter = ({ count, displayMob }) => {
  const categories = Object.keys(cats);
  const filters = useSelector((state) => state.filters);
  const dispatch = useDispatch();

  //Handle dynamic filter change
  const handleFilter = (evt, filterProperty) => {
    const newFilters = { ...filters };
    newFilters[filterProperty] = evt.target.value;
    dispatch(setFilters(newFilters));
  };

  //Handle page number drop-down, Dynmaic as per number of query results
  const pageSelector = (filters) => {
    let options = [];
    const pages =
      count <= filters.displayCount ? 1 : ~~(count / filters.displayCount) + 1;
    for (let i = 1; i <= pages; i++) {
      options.push(
        <option  value={`${i}`} key={i}>
          {i}
        </option>
      );
    }
    return options;
  };

  //Handle next page;
  const goToNext = () => {
    const pages =
      count <= filters.displayCount ? 1 : ~~(count / filters.displayCount) + 1;
    if (filters.page < pages) {
      dispatch(nextPage());
    }
  };

  //Handle previous page;
  const goToPrev = () => {
    const pages =
      count <= filters.displayCount ? 1 : ~~(count / filters.displayCount) + 1;
    if (filters.page > 1) {
      dispatch(prevPage());
    }
  };

  return (
    <div className='filter' id={displayMob ? '' : 'filter-hide'}>
      {/* Sorty by propery ie. product price or prduct name */}
      <div className='search-pan'>
        <select
          onChange={(evt) => handleFilter(evt, 'categorie')}
          name='searchField'
          id='search-field'
          value={filters.categorie}
        >
          <option value=''>CATEGORIES</option>
          {categories.map((d, idx) => (
            <option value={d} key={idx}>
              {truncate(d, 25)}
            </option>
          ))}
        </select>
        <input
          className='search-bar'
          onChange={(evt) => handleFilter(evt, 'searchStr')}
          value={filters.searchStr}
          type='text'
          name='title'
          id='title'
          placeholder='Search an Equipment or Product'
        />
      </div>

      <div>
        <FormControlLabel
          control={
            <Switch
              checked={filters.isValidated}
              onChange={(evt) => dispatch(setValidation(!filters.isValidated))}
            />
          }
          label='Validated'
        />
      </div>

      {/* Number of results per page */}
      <div className='pages'>
        <FontAwesomeIcon className='filter-icon' icon='th' />
        <select
          name='results-per-page'
          id='results-per-page'
          onChange={(evt) => dispatch(setDisplayCount(evt.target.value))}
        >
          <option value='20'>20</option>
          <option value='40'>40</option>
          <option value='60'>60</option>
          <option value='80'>80</option>
          <option value='100'>100</option>
        </select>
        <FontAwesomeIcon className='filter-icon' icon='forward' />
        <select
          value={filters.page.toString()}
          name='page'
          id='page'
          onChange={(evt) => dispatch(setPage(evt.target.value))}
        >
          {pageSelector(filters)}
        </select>
        <FontAwesomeIcon
          onClick={goToPrev}
          className='filter-icon'
          icon='step-backward'
        />
        <FontAwesomeIcon
          onClick={goToNext}
          className='filter-icon'
          icon='step-forward'
        />
      </div>
    </div>
  );
};

export default Filter;
