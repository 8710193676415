import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState, React } from 'react';
import { useDispatch } from 'react-redux';
import SEO from '../components/seo';
import { useForm } from 'react-hook-form';
import toast, { Toaster } from 'react-hot-toast';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import countries from '../data/countries.json';
import styles from './../components/listingform.module.css';
import Navbar from '../components/navbar';
import isLoggedIn from '../utils/isLoggedIn';

const ManageShipping = () => {
  // Initial state and variables ---------------------------------------------------\\

  const url = `${process.env.REACT_APP_API}/admin`;
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [product, setProduct] = useState(); //Product details state
  const [isloading, setLoading] = useState(true); //Loading isloading toggle
  const token = sessionStorage.getItem('token');
  const [error, setError] = useState('');
  const [spinner, setSpinner] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch()

  // UseEffects, network calls ---------------------------------------------------\\

  useEffect(() => {
    if (error) {
      toast.error('Sorry something went wrong. Please try again!', {
        duration: 4000,
      });
      setSpinner(false);
    }
  }, [error]);

  // Query to get product data
  useEffect(() => {
    //check if user is logged in
    isLoggedIn(navigate, dispatch, `/listing/shipping/${id}`);

    let active = true;
    (async () => {
      const productData = await axios(`${url}/shipping/product/${id}`, {
        headers: { authorization: `Bearer ${token}` },
      });
      if (active) {
        setProduct(productData.data);
        setLoading(false);
      }
    })();
    return () => {
      active = false;
      setProduct(null);
    };
  }, [id]);

  // Form submit handler ---------------------------------------------------\\
  const handleShippingUpdate = async (data) => {
    if (!spinner) {
      setSpinner(true);
    }

    try {
      const response = await axios.post(`${url}/shipping/update/${id}`, data, {
        headers: { authorization: `Bearer ${token}` },
      });
      if (response.status === 201) {
        toast.success(response.data?.message, { duration: 3000 });
        setSpinner(false);
        return setTimeout(() => {
          navigate(`/product/${id}`);
        }, 4000);
      }
    } catch (err) {
      toast.error(err.message, { duration: 4000 });
      setSpinner(false);
    }
  };

  return isloading ? (
    // Waiting data loading after async request...
    <>
      <SEO
        title={`BADGERS: Manage shipping`}
        page='manage shipping'
        description={`BADGERS: manage shipping`}
        name='BADGERS'
        type='Product'
      />
      <div className='loading'>
        <img src='/img/loading.gif' alt='Loading' className='loading-img' />
      </div>
    </>
  ) : (
    <>
      <SEO
        title={`BADGERS: Manage shipping`}
        page='manage shipping'
        description={`BADGERS: manage shipping`}
        name='BADGERS'
        type='Product'
      />
      <Navbar displayMob={true} />
      <div className={styles.pan}>
        <Toaster position='top-center' reverseOrder={false} />

        <form className='row g-3' onSubmit={handleSubmit(handleShippingUpdate)}>
          {/* ----------------------- */}
          <div className=''>
            <Link className='page-links' to='/'>
              <FontAwesomeIcon icon='home' />{' '}
            </Link>
            <span className='link-span'>
              <FontAwesomeIcon icon='angle-right' />
            </span>
            <Link className='page-links' to='/listings'>
              LISTINGS{' '}
            </Link>
            <span className='link-span'>
              <FontAwesomeIcon icon='angle-right' />
            </span>
            <Link className='link-span' to={`/product/${product.id}`}>
              {product.title}
            </Link>
            <span className='link-span'>
              <FontAwesomeIcon icon='angle-right' />
            </span>
            <span className='link-span'>UPDATE SHIPPING</span>
          </div>
          {/* ----------------------- */}
          <div className='col-12 d-flex justify-content-center my-4'>
            <h4 className='h4'>
              Update Shipping Details <FontAwesomeIcon icon='truck-moving' />
            </h4>
          </div>

          {/* ----------------------------------------------- */}

          <div className={`${styles.card} row mb-2 sellfirst-zone`}>
            <div className='mb-3 col-12'>
              <label htmlFor='title' className='form-label'>
                Product
              </label>
              <input
                defaultValue={product.title}
                disabled
                type='text'
                id='title'
                name='title'
                placeholder='Choose a brief title for your listing.'
                className='form-control'
              />
            </div>
            <div className='my-2 col-md-6'>
              <label htmlFor='brand' className='form-label'>
                Manufacturer, Brand
              </label>
              <input
                defaultValue={product?.brand}
                disabled
                type='text'
                id='brand'
                name='brand'
                placeholder='Please enter product manufacturer or brand name.'
                className='form-control'
              />
            </div>
            <div className='my-2 col-md-6'>
              <label htmlFor='prix' className='form-label'>
                Catalogue Price
              </label>
              <input
                defaultValue={`${product.price} ${
                  product?.currency === 'usd' ? '$' : '€'
                }`}
                disabled
                type='text'
                id='prix'
                name='prix'
                className='form-control'
              />
            </div>
          </div>

          {/* ----------------------------------------------- */}

          <div className={`${styles.card} row mb-2 sellsecond-zone`}>
            <div className='my-2 col-md-4'>
              <label htmlFor='' className='form-label'>
                Length (cm)
              </label>
              <input
                defaultValue={product.length}
                type='number'
                id='packlength'
                name='packlength'
                className='form-control'
                {...register('packlength', {
                  required: 'Package length is required.',
                  min: {
                    value: 1,
                    message: 'Please a enter a valid length in cm.',
                  },
                  max: {
                    value: 10000,
                    message: 'Package length exceeds the maximum allowed.',
                  },
                })}
              />
              {errors?.packlength && (
                <p className='help-block text-danger my-2'>
                  {errors.packlength?.message}
                </p>
              )}
            </div>
            <div className='my-2 col-md-4'>
              <label htmlFor='width' className='form-label'>
                Width (cm)
              </label>
              <input
                defaultValue={product.width}
                type='number'
                id='width'
                name='width'
                className='form-control'
                {...register('width', {
                  required: 'Package width is required.',
                  min: {
                    value: 1,
                    message: 'Please a enter valid width in cm.',
                  },
                  max: {
                    value: 10000,
                    message: 'Package width exceeds the maximum allowed.',
                  },
                })}
              />
              {errors?.width && (
                <p className='help-block text-danger my-2'>
                  {errors.width?.message}
                </p>
              )}
            </div>
            <div className='my-2 col-md-4'>
              <label htmlFor='height' className='form-label'>
                Height (cm)
              </label>
              <input
                defaultValue={product.height}
                type='number'
                id='height'
                name='height'
                className='form-control'
                {...register('height', {
                  required: 'Package height is required.',
                  min: {
                    value: 1,
                    message: 'Please a enter valid height in cm.',
                  },
                  max: {
                    value: 10000,
                    message: 'Package height exceeds the maximum allowed.',
                  },
                })}
              />
              {errors?.height && (
                <p className='help-block text-danger my-2'>
                  {errors.height?.message}
                </p>
              )}
            </div>
            <div className='my-2 col-md-6'>
              <label htmlFor='weight' className='form-label'>
                Weight (Kg)
              </label>
              <input
                defaultValue={product.weight}
                step='.01'
                type='number'
                id='weight'
                name='weight'
                className='form-control'
                {...register('weight', {
                  required: 'Package weight is required.',
                  min: {
                    value: 0.1,
                    message: 'Please a enter valid weight, 0.1 kg minimum.',
                  },
                  max: {
                    value: 9999,
                    message: 'Package weight exceeds the maximum allowed.',
                  },
                })}
              />
              {errors?.weight && (
                <p className='help-block text-danger my-2'>
                  {errors.weight?.message}
                </p>
              )}
            </div>
            <div className='my-2 col-md-6'>
              <label htmlFor='hscode' className='form-label'>
                HS Code
              </label>
              <input
                defaultValue={product.hscode}
                type='text'
                id='hscode'
                name='hscode'
                className='form-control'
                {...register('hscode', {
                  required: 'HS code is is required.',
                  minLength: {
                    value: 6,
                    message: 'HS code length can not be less than six.',
                  },
                  maxLength: {
                    value: 10,
                    message: 'HS code length exceeds valid length.',
                  },
                })}
              />
              {errors?.hscode && (
                <p className='help-block text-danger my-2'>
                  {errors.hscode?.message}
                </p>
              )}
            </div>
          </div>

          {/* ----------------------------------------------- */}

          <div className={`${styles.card} row mb-2 sellsecond-zone`}>
            <div className='my-2 col-md-6'>
              <label htmlFor='country' className='form-label'>
                Country
              </label>
              <select
                id='type'
                name='type'
                className='form-select form-control'
                {...register('country', {
                  required:
                    'Please select a country from the list, where product is to be delivered.',
                  minLength: 3,
                })}
              >
                <option className='default-select' value=''>
                  Select Delivery Location
                </option>
                {countries.map((obj, idx) => (
                  <option
                    value={obj.name}
                    key={idx}
                    selected={obj.name == product?.country}
                  >
                    {obj.name}
                  </option>
                ))}
              </select>
              {errors?.country && (
                <p className='help-block text-danger my-2'>
                  {errors.country?.message}
                </p>
              )}
            </div>
            <div className='my-2 col-md-6'>
              <label htmlFor='city' className='form-label'>
                City
              </label>
              <input
                defaultValue={product.city}
                type='text'
                id='city'
                name='city'
                className='form-control'
                {...register('city', {
                  required: 'Please enter the city where product is located.',
                  minLength: {
                    value: 3,
                    message: 'Please a enter a valid city name.',
                  },
                  maxLength: {
                    value: 50,
                    message: 'City name length exceeds the valid name.',
                  },
                })}
              />
              {errors?.city && (
                <p className='help-block text-danger my-2'>
                  {errors.city?.message}
                </p>
              )}
            </div>
            <div className='my-2 col-md-6'>
              <label htmlFor='postcode' className='form-label'>
                Post Code
              </label>
              <input
                defaultValue={product.postcode}
                type='text'
                id='postcode'
                name='postcode'
                className='form-control'
                {...register('postcode', {
                  required: 'Postcode is required.',
                  minLength: {
                    value: 3,
                    message: 'Please a enter a valid quantity.',
                  },
                  maxLength: {
                    value: 20,
                    message: 'Quantity exceeds the maximum allowed.',
                  },
                })}
              />
              {errors?.postcode && (
                <p className='help-block text-danger my-2'>
                  {errors.postcode?.message}
                </p>
              )}
            </div>
            <div className='my-2 col-md-6'>
              <label htmlFor='isShippable' className='my-2 form-label'>
                Is Shippable?:
              </label>
              <div>
                <div className='form-check form-check-inline'>
                  <input
                    {...register('isShippable', {
                      required:
                        'Please a select if product can be shipped or not.',
                    })}
                    type='radio'
                    defaultChecked={product?.isShippable === true}
                    id='yes'
                    name='isShippable'
                    value='true'
                    className='form-check-input'
                  />
                  <label htmlFor='yes' className='form-check-label'>
                    Yes{' '}
                  </label>
                </div>
                <div className='form-check form-check-inline'>
                  <input
                    {...register('isShippable', {
                      required:
                        'Please a select if product can be shipped or not.',
                    })}
                    type='radio'
                    defaultChecked={product?.isShippable === false}
                    id='no'
                    name='isShippable'
                    value='false'
                    className='htmlForm-check-input'
                  />
                  <label htmlFor='no' className='htmlForm-check-label'>
                    No{' '}
                  </label>
                </div>
                {errors?.isShippable && (
                  <p className='help-block text-danger my-2'>
                    {errors.isShippable?.message}
                  </p>
                )}
              </div>
            </div>

            {!spinner && (
              <div className='d-flex gap-2 my-3'>
                <Link
                  className={`btn btn-danger ${styles.btncancel}`}
                  to={`/product/${id}`}
                >
                  Cancel
                </Link>
                <button type='submit' className={`btn ${styles.btnsubmit}`}>
                  Update Shipping {'  '}
                  <FontAwesomeIcon icon='paper-plane' />
                </button>
              </div>
            )}
            {spinner && (
              <div className='d-grid gap-2 my-3'>
                <button className={`btn ${styles.btnsubmit}`}>
                  Sending data...
                  <div
                    className='spinner-grow spinner-grow-sm'
                    role='status'
                  ></div>
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default ManageShipping;
