import styles from './../pages/product.module.css';
import React from 'react';


const CartDetails = ({ cart }) => {


  return (
    <>
      <div className='product-block col-md-6'>
        <h5 className='h5'>{`Cart ID: ${cart.id}`}</h5>
        <p className='product-subheads product-type mb-2'>
          <span className={styles.label}>Created on:</span>
          <span className={styles.value}>
            {new Date(cart.createdAt).toLocaleString('en-UK', {
              dateStyle: 'long',
              timeStyle: 'short',
            })}
          </span>
        </p>
        <p className='product-subheads product-type mb-2'>
          <span className={styles.label}>Updated on:</span>
          <span className={styles.value}>
            {new Date(cart.updatedAt).toLocaleString('en-UK', {
              dateStyle: 'long',
              timeStyle: 'short',
            })}
          </span>
        </p>
        <p className='product-subheads product-type mb-2'>
          <span className={styles.label}>Expires:</span>
          <span className={styles.value}>
            {new Date(parseInt(cart.expires)).toLocaleString('en-UK', {
              dateStyle: 'long',
              timeStyle: 'short',
            })}
          </span>
        </p>
        <p className='product-subheads product-type mb-2 mt-3'>
          <span className={styles.label}>Price:</span>
          <span className={styles.value}>{`${parseFloat(cart.price).toFixed(
            2
          )} ${cart.currency === 'usd' ? 'US$' : '€'}`}</span>
        </p>
        <p className='product-subheads product-type mb-2'>
          <span className={styles.label}>VAT:</span>
          <span className={styles.value}>{`${parseFloat(cart.vat).toFixed(
            2
          )} ${cart.currency === 'usd' ? 'US$' : '€'}`}</span>
        </p>
        <p className='product-subheads product-type mb-2'>
          <span className={styles.label}>Shipping:</span>
          <span className={styles.value}>{`${parseFloat(
            cart.shipping
          ).toFixed(2)} ${cart.currency === 'usd' ? 'US$' : '€'}`}</span>
        </p>
        <p className='product-subheads product-type mb-2'>
          <span className={styles.label}>Grand Total:</span>
          <span className={`${styles.value} fw-bold`}>{`${parseFloat(
            cart.total
          ).toFixed(2)} ${cart.currency === 'usd' ? 'US$' : '€'}`}</span>
        </p>
      </div>
    </>
  );
};

export default CartDetails;
