import { createSlice } from '@reduxjs/toolkit'

const initialsearch =  {
    search:'',
    origin:''
}

const slice = createSlice({
    name: 'search',
    initialState: {
        search: initialsearch,
    },
    reducers: {
        SetSearch: (state, action) => {
        state.search = action.payload
        },
        DeleteSearch: (state, action) => {
            state.search = '';
        }
    },
});
export default slice.reducer;
export const {SetSearch, DeleteSearch} = slice.actions