import styles from './../pages/product.module.css';
import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';


const CompanyCard = ({ company }) => {
  const [confirmDelete, toggleConfirmDelete] = useState(false);
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.user);

  // Delete company  handler ------------------------------------------------>>

  const deleteCompany = async () => {
    axios
      .delete(
        `${process.env.REACT_APP_API}/admin/company/${company.id}`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        if (response.status === 204) {
          toast.success(
            'Company profile and related data was successfully deleted from the database.',
            {
              duration: 3000,
            }
          );
          setTimeout(() => {
            navigate('/companies');
          }, 2000);
        } 
      })
      .catch((err) => {
        toast.error(err.response.data.error, {
          duration: 3000,
        });
      });
  };

  //Delete confirmation button toggle handler
  const toggleDelete = () => {
    toggleConfirmDelete((state) => !state);
  };

  return company ? (
    <>
      <div className='product-block col-md-6'>
        <Toaster position='top-center' reverseOrder={false} />
        <h4 className='h4'>{`Company: ${company?.raisonSociale?.toUpperCase()}`}</h4>
        <h5 className='h5'>{`${company.ville}, ${company?.pays}`}</h5>
        <p className='product-subheads product-type mb-2 mt-3'>
          <span className={styles.label}>SIRET:</span>
          <span className={styles.value}>{company?.siret}</span>
        </p>
        <p className='product-subheads product-type mb-2'>
          <span className={styles.label}>Email:</span>
          <span className={styles.value}>{company?.email}</span>
        </p>
        <p className='product-subheads product-type mb-2'>
          <span className={styles.label}>Tel:</span>
          <span className={styles.value}>{company?.contact}</span>
        </p>
        <p className='product-subheads product-brand mb-2'>
          <span className={styles.label}>Address:</span>
          <span
            className={styles.value}
          >{`${company?.address}, ${company?.postcode}`}</span>
        </p>
        <p className='product-subheads product-brand mb-2'>
          <span className={styles.label}>City:</span>
          <span className={styles.value}>{company?.ville}</span>
        </p>
        <p className='product-subheads product-brand mb-2'>
          <span className={styles.label}>Country:</span>
          <span className={styles.value}>{company?.pays}</span>
        </p>
        <p className='product-subheads product-brand mb-2'>
          <span className={styles.label}>Key Person:</span>
          <span className={styles.value}>{company?.dg}</span>
        </p>
        <p className='product-subheads product-brand mb-2'>
          <span className={styles.label}>CreatedAt:</span>
          <span className={styles.value}>
            {' '}
            {new Date(company?.createdAt).toLocaleString('en-UK', {
              dateStyle: 'short',
              timeStyle: 'short',
            })}
          </span>
        </p>
        <p className='product-subheads product-brand mb-2'>
          <span className={styles.label}>UpdatedAt:</span>
          <span className={styles.value}>
            {new Date(company?.updatedAt).toLocaleString('en-UK', {
              dateStyle: 'short',
              timeStyle: 'short',
            })}
          </span>
        </p>

        <div className='mb-1'>
          <button
            className={`${styles.edit} ${styles.btn}`}
            onClick={() => navigate(`/company/update/${company.id}`)}
          >
            {`UPDATE DETAILS `}
            <FontAwesomeIcon icon='pen-square' />
          </button>
        </div>
        <div className='mb-1'>
          <button
            className={`${styles.list} ${styles.btn}`}
            onClick={() =>
              navigate(`/users/company/${company.id}`, {
                state: { companyName: `${company.raisonSociale}` },
              })
            }
          >
            {`COMPANY USERS  `}
            <FontAwesomeIcon icon='user' />
          </button>
        </div>

        {confirmDelete ? (
          <div>
            <button
              className={`${styles.del} ${styles.btn}`}
              onClick={deleteCompany}
            >
              {`DELETE ANYWAY??  `}
              <FontAwesomeIcon icon='trash' />
            </button>
          </div>
        ) : (
          <div>
            <button
              className={`${styles.del} ${styles.btn}`}
              onClick={toggleDelete}
            >
              {`DELETE COMPANY  `}
              <FontAwesomeIcon icon='trash' />
            </button>
          </div>
        )}
      </div>
    </>
  ) : (
    <div></div>
  );
};

export default CompanyCard;
