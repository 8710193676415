import React from 'react'

const Banner
 = () => {
  return (
    <div className='pan-image'>
      <img
        className='pan-image'
        src='/img/landingbg_1.jpg'
        alt='Pan Image'
      />
    </div>
  );
}

export default Banner
