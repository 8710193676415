import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import user from './user';
import filters from './filters';
import eqpt from './eqpt';
import qte from './qte';
import selectedEqpt from './selectedEqpt';
import search from './search';
import access from './access';
import activePage from './activePage';
import favs from './favs'
import featured from './featured';
const reducer = combineReducers({
  user: user,
  favs: favs,
  filters: filters,
  eqpt: eqpt,
  selectedEqpt: selectedEqpt,
  qte: qte,
  search: search,
  access: access,
  activePage: activePage,
  featured: featured
});
const store = configureStore({
  reducer,
})
export default store;