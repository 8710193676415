import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styles from './../../pages/listings.module.css';
import useAnalyticsReport from '../../redux/hooks/useAnalyticsReport';
import analyticsstyles from './analytics.module.css';
import ChartUsersOverTime from './chart_users_overtime';
import createSeries from './../../utils/createSeries';


const UsersByCountry = ({history}) => {
  //Compose report, data to be fetched.
  const report = useMemo(
    () => ({
      dimensions: [{ name: 'country' }, { name: 'date' }],
      metrics: [{ name: 'totalUsers' }],
      dateRanges: [{ startDate: `${history}daysAgo`, endDate: 'today' }],
      orderBys: [
        {
          dimension: { orderType: 'NUMERIC', dimensionName: 'date' },
          desc: true,
        },
      ],
      metricAggregations: ['TOTAL'],
    }),
    [history]
  );

  //initial state and hooks invocation
  const { token } = useSelector((state) => state.user);
  const [usersByCountry, loadingUsers] = useAnalyticsReport(token, report);



  return loadingUsers ? (
    <div className='loading'>
      <img src='/img/loading.gif' alt='Loading' className='loading-img' />
    </div>
  ) : (
    <div className={analyticsstyles.rcchart2}>
      <ChartUsersOverTime
        series={createSeries(usersByCountry?.rows)}
        caption={'Users Evolution by Country'}
      />
    </div>
  );
};

export default UsersByCountry;
