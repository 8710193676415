import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect } from 'react';
import isLoggedIn from '../utils/isLoggedIn';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useNavigate, Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import countries from '../data/countries.json';
import styles from './signup.module.css';
import Navbar from '../components/navbar';

const AddCompany = () => {
  // Initial state and variables ---------------------------------------------------\\

  const url = `${process.env.REACT_APP_API}/admin/companies/enroll`;
  const { token } = useSelector((state) => state.user);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [feedback, setFeedback] = useState('');
  const [apiErrs, setApierrs] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch()

  // Handle enroll ---------------------------------------------------\\
  const handleEnroll = async (data) => {
    const {
      raisonSociale,
      siret,
      pays,
      ville,
      postcode,
      address,
      contact,
      email,
      dg,
      ca,
    } = data;
    const myHeaders = new Headers();
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Content-Type', 'application/json');

    try {
      const newCompany = {
        raisonSociale,
        siret,
        pays,
        ville,
        postcode,
        address,
        contact,
        email,
        dg,
        ca,
      };
      setSpinner(true);
      const response = await axios.post(`${url}`, newCompany, {
        headers: { authorization: `Bearer ${token}` },
      });
      if (response.status === 201) {
        toast.success(response.data.message, {
          duration: 4000,
        });
        setSpinner(false);
        setApierrs([]);
        reset();
      } else {
        setFeedback(
          `Error Code: ${response.status}. \n OOOOPS.. ${response.data.message}`
        );
      }
    } catch (error) {
      setApierrs(error.response.data.errors);
      setSpinner(false);
    }
  };

  // Map API Errors ---------------------------------------------------\\
  const mapErrs = (obj) => {
    const keys = Object.keys(obj);
    return (
      <p className='help-block text-danger my-2'>
        {`${keys[0]}: `}
        {obj[keys[0]]}
      </p>
    );
  };


    useEffect(() => {
      isLoggedIn(navigate, dispatch, '/companies/enroll');
    }, []);

  // Rendered page ---------------------------------------------------\\
  return (
    <>
      <Helmet>
        <title>Badgers SAS: Enroll company</title>
        <meta name='page' content='enroll company' />
      </Helmet>
      <Navbar displayMob={true} />
      <div className={styles.pan}>
        <Toaster position='top-center' reverseOrder={false} />
        <form
          onSubmit={handleSubmit(handleEnroll)}
          className='row g-3'
          action=''
        >
          {/* ----------------------- */}
          <div className=''>
            <Link className='page-links' to='/'>
              <FontAwesomeIcon icon='home' />{' '}
            </Link>
            <span className='link-span'>
              <FontAwesomeIcon icon='angle-right' />
            </span>
            <Link className='page-links' to='/companies'>
              COMPANIES{' '}
            </Link>
            <span className='link-span'>
              <FontAwesomeIcon icon='angle-right' />
            </span>
            <span className='link-span'>ENROLLMENT</span>
          </div>
          {/* ----------------------- */}
          <div className='col-12 d-flex justify-content-center my-4'>
            <h5 className='h5'>
              Enroll a Company <FontAwesomeIcon icon='building' />
            </h5>
          </div>
          <div className={`${styles.card} row`}>
            <div className='my-2 col-md-6'>
              <input
                className='form-control'
                type='text'
                name='raisonSociale'
                id='raisonSociale'
                placeholder='Corporate Name'
                {...register('raisonSociale', {
                  required: 'Please enter the company name.',
                  maxLength: 60,
                  minLength: 2,
                })}
              />
              {errors?.raisonSociale && (
                <p className='help-block text-danger my-2'>
                  {errors.raisonSociale?.message}
                </p>
              )}
            </div>
            <div className='my-2 col-md-6'>
              <input
                className='form-control'
                type='text'
                name='siret'
                id='siret'
                placeholder='SIRET, Registration Number'
                {...register('siret', {
                  maxLength: 30,
                  minLength: 3,
                })}
              />
              {errors?.siret && (
                <p className='help-block text-danger my-2'>
                  {errors.siret?.message}
                </p>
              )}
            </div>
            <div className='my-2 col-md-6'>
              <input
                className='form-control'
                type='email'
                name='email'
                id='email'
                placeholder='Email Address'
                {...register('email', {
                  required: 'Please enter a valid email address.',
                })}
              />
              {errors?.email && (
                <p className='help-block text-danger my-2'>
                  {errors.email?.message}
                </p>
              )}
            </div>
            <div className='my-2 col-md-6'>
              <input
                className='form-control'
                type='text'
                name='contact'
                id='contact'
                placeholder='Contact Number'
                {...register('contact', {
                  required: 'Please enter a valid telephone number.',
                })}
              />
              {errors?.contact && (
                <p className='help-block text-danger my-2'>
                  {errors.contact?.message}
                </p>
              )}
            </div>
            <div className='my-2 col-md-6'>
              <input
                className='form-control'
                type='text'
                name='address'
                id='address'
                placeholder='Company street address'
                {...register('address', {
                  required: 'Please enter your company address.',
                })}
              />
              {errors?.address && (
                <p className='help-block text-danger my-2'>
                  {errors.address?.message}
                </p>
              )}
            </div>
            <div className='my-2 col-md-6'>
              <input
                className='form-control'
                type='text'
                name='postcode'
                id='postcode'
                placeholder='Post Code'
                {...register('fonction', {})}
              />
              {errors?.postcode && (
                <p className='help-block text-danger my-2'>
                  {errors.postcode?.message}
                </p>
              )}
            </div>
            <div className='my-2 col-md-6'>
              <select
                id='pays'
                name='pays'
                className='form-select form-control'
                {...register('pays', {
                  required: 'Please enter the country, company is based in.',
                })}
              >
                <option className='default-select' value=''>
                  Select Your Location
                </option>
                {countries.map((obj, idx) => (
                  <option value={obj.name} key={idx}>
                    {obj.name}
                  </option>
                ))}
              </select>
              {errors?.pays && (
                <p className='help-block text-danger my-2'>
                  {errors.pays?.message}
                </p>
              )}
            </div>
            <div className='my-2 col-md-6'>
              <input
                className='form-control'
                type='text'
                name='ville'
                id='ville'
                placeholder='City or State'
                {...register('ville', {
                  required: 'Please enter the city or state name.',
                })}
              />
              {errors?.ville && (
                <p className='help-block text-danger my-2'>
                  {errors.ville?.message}
                </p>
              )}
            </div>
            <div className='my-2 col-md-6'>
              <input
                className='form-control'
                type='text'
                name='dg'
                id='dg'
                placeholder='Key Person'
                {...register('dg', {
                  required: "Please enter key person's name.",
                  minLength: 8,
                })}
              />
              {errors?.dg && (
                <p className='help-block text-danger my-2'>
                  {errors.dg?.message}
                </p>
              )}
            </div>
            <div className='my-2 col-md-6'>
              <input
                className='form-control'
                type='number'
                name='ca'
                id='ca'
                placeholder='Annual Turnover'
                {...register('ca', {
                  min: 1,
                })}
              />
              {errors?.ca && (
                <p className='help-block text-danger my-2'>
                  {errors.ca?.message}
                </p>
              )}
            </div>
            {apiErrs.validation && (
              <p className='help-block text-danger my-2'>
                {errors.validation?.message}
              </p>
            )}
            {apiErrs.length > 0 && apiErrs.map((obj) => mapErrs(obj))}
            <div className='newsletter'>
              <p className='help-block text-danger my-2'>{feedback}</p>
            </div>
            <div className='row justify-content-center mt-4'>
              {spinner ? (
                <button type='' className={`btn ${styles.btnsignup}`}>
                  Sending data ..
                  <div
                    className='spinner-grow spinner-grow-sm'
                    role='status'
                  ></div>
                </button>
              ) : (
                <button type='submit' className={`btn ${styles.btnsignup}`}>
                  Enroll Company <FontAwesomeIcon icon='building' />
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddCompany;
